export class IUseCase {
    /**
     * Execute metod for use case
     * @param {*} params - Parameters for the use case
     * @returns {*}
     * @throws {Error} If the method is not implemented
     */
    async execute(params) {
        throw new Error('Method not implemented');
    }
}