import React, { createContext, useState, useContext, useEffect } from 'react';
import { getCookie, saveCookie } from '../../../core/cookies/cookiesUtils';
import useGetClientExecution from '../presentation/hooks/use-get-client-executions.hook';

/**
 * @typedef {import('../../../modules/users/domain/entities/users.entity').ClientEntity} ClientEntity
 * @typedef {import('../../../modules/users/domain/entities/users.entity').UserEntity} UserEntity
 * @typedef {import('../../../modules/clientExecution/domain/entities/clientUserExecution.entity').ClientUserAgentExecutionEntity} ClientUserAgentExecutionEntity
 * @typedef {import('../../../modules/clientExecution/domain/entities/clientUserExecution.entity').ClientUserAgentExecutionResponseDTO} ClientUserAgentExecutionResponseDTO
 */

/**
 * @typedef {Object} ExecuteAgentContextType
 * @property {string|null} userToken - The user authentication token
 * @property {ClientEntity|null} client - The client data
 * @property {ClientUserAgentExecutionEntity[]|null} clientExecutions - The client executions data
 * @property {boolean} loadingExecutions - Flag indicating if executions are loading
 * @property {function(string): void} updateUserToken - Function to update the user token
 * @property {function(ClientEntity): void} updateClient - Function to update the client data
 */

/** @type {React.Context<ExecuteAgentContextType>} */
const ExecuteAgentContext = createContext();

/**
 * Provider to manage the execution and clients context
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components
 * @returns {JSX.Element} Provider component
 */
export const ExecuteAgentProvider = ({ children }) => {
  const {
    executeCallback: getClientExecutions,
    executions,
    loading: loadingExecutions,
  } = useGetClientExecution();
  const [userToken, setUserToken] = useState(null);
  const [client, setClient] = useState(null);

  useEffect(() => {
    try {
      const userToken = getCookie('__auth.session-token');
      const clientData = getCookie('__auth.session-clientData');

      if (userToken) {
        setUserToken(userToken);
      }

      if (clientData) {
        setClient(JSON.parse(clientData));
      }
    } catch (error) {
      console.error('Error al cargar datos de usuario/cliente:', error);
    }
  }, []);

  useEffect(() => {
    if (userToken) {
      getClientExecutions();
    }
  }, [client]);

  /**
   * Updates the user token and saves it to cookies
   * @param {string} newUserData - The new user token
   */
  const updateUserToken = (newUserData) => {
    saveCookie('__auth.session-token', newUserData, {
      maxAge: 60 * 60 * 24 * 7, // 7 days
    });
    setUserToken(newUserData);
  };

  /**
   * Updates the client data and saves it to cookies
   * @param {ClientEntity} newClientData - The new client data
   */
  const updateClient = (newClientData) => {
    const newClientStr = JSON.stringify(newClientData);
    saveCookie('__auth.session-clientData', newClientStr, {
      maxAge: 60 * 60 * 24 * 2, // 2 days
    });
    setClient(newClientData);
  };

  /** @type {ExecuteAgentContextType} */
  const value = {
    userToken,
    client,
    clientExecutions: executions,
    loadingExecutions,
    updateUserToken,
    updateClient,
  };

  return (
    <ExecuteAgentContext.Provider value={value}>
      {children}
    </ExecuteAgentContext.Provider>
  );
};

/**
 * Hook to use the ExecuteAgent context
 * @returns {ExecuteAgentContextType} The context value
 */
export const useExecuteAgentContext = () => useContext(ExecuteAgentContext);
