import { ApiService } from '../../../../core/api/apiService';
import AGENT_CLIENTS_ENDPOINTS from '../endpoints/clients.endpoints';

/**
 * @typedef {import('../../../users/domain/entities/users.entity').ClientEntity} ClientEntity
 */

export class GetUserClientsRepository extends ApiService {
  constructor(failureHandler) {
    super(failureHandler);
    this.failureHandler = failureHandler;
  }

  /**
   * Get clients by name
   * @param {Object} data - Data to search clients
   * @param {string} data.name - Name of the client
   * @returns {Promise<ClientEntity[]>} Response from the agent manager
   * @throws {Error} Failure to implement the method
   */
  async getClientsByName(data) {
    const responseData = await this.get(
      AGENT_CLIENTS_ENDPOINTS.getAdminClients,
      data
    );

    return responseData;
  }
}
