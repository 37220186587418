import { ApiService } from "../../../../core/api/apiService";
import AGENTS_ENDPOINTS from "../endpoints/agents.endpoints";


export class GetAgentWithFlowsRepository extends ApiService {
  constructor(failureHandler) {
    super(failureHandler);
    this.failureHandler = failureHandler;
  }

  async getAgentWithFlows(id){
    const responseData = await this.get(
      `${AGENTS_ENDPOINTS.getAgentWithFlows}/${id}/flows`,
    );

    return responseData;
  }
}
