import { Fragment, useEffect, useState } from 'react';
import { useExecuteAgentContext } from '../../contexts/ExecuteAgents.context';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import ExecutionsStyles from '../styles/ExecutionsPage.styles';
import Loader from '../../components/Loader';
import SVGComponent from '../../components/SVGComponent';

const ExecutionNavbar = ({ navElements }) => {
  const [activeSection, setActiveSection] = useState('');

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      setActiveSection(id);
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <ExecutionsStyles.ExecutionNavContainer>
      <div
        style={{
          position: 'relative',
          zIndex: 10,
          display: 'flex',
          width: '36.629px',
          height: '36.629px',
          padding: '9.157px',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '7.631px',
          border: '0.763px solid #eaecf0',
          boxShadow: '0px 0.763px 1.526px 0px rgba(16, 24, 40, 0.05)',
        }}
      >
        <SVGComponent
          src={'/v1/swarm/left-indent-01.svg'}
          strokeColor={'#000000'}
        />
      </div>

      <ExecutionsStyles.NavbarTitleWrapper>
        <ExecutionsStyles.NavbarPrimaryTitle>
          Índice de la ejecución
        </ExecutionsStyles.NavbarPrimaryTitle>
        <ExecutionsStyles.NavbarSubTitle>
          Navega por las secciones de la ejecución
        </ExecutionsStyles.NavbarSubTitle>
      </ExecutionsStyles.NavbarTitleWrapper>

      <ExecutionsStyles.NavElementsWrapper>
        {navElements.map((id, index) => (
          <ExecutionsStyles.NavItem
            key={index}
            borderColor={activeSection === id ? '#2240D9' : undefined}
            className={activeSection === id ? 'active' : ''}
            onClick={() => scrollToSection(id)}
          >
            <ExecutionsStyles.NavElementImgWrapper>
              <SVGComponent
                src={'/v1/swarm/layers.svg'}
                strokeColor={'#2240D9'}
              />
            </ExecutionsStyles.NavElementImgWrapper>
            <ExecutionsStyles.NavElementText>
              {id.replace(/-/g, ' ')}
            </ExecutionsStyles.NavElementText>
          </ExecutionsStyles.NavItem>
        ))}
      </ExecutionsStyles.NavElementsWrapper>
    </ExecutionsStyles.ExecutionNavContainer>
  );
};

const ExecutionsPage = () => {
  const { id } = useParams();
  const { clientExecutions } = useExecuteAgentContext();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedExecution, setSelectedExecution] = useState(null);
  const [messages, setMessages] = useState([]);
  const [navElements, setNavElements] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    if (clientExecutions) {
      const execution = clientExecutions.find(
        (execution) => execution.id === id
      );
      setSelectedExecution(execution);

      if (execution) {
        const variables = execution.variables;
        //TODO --> Implement a better way to filter users usefull messages from history
        const messages = Object.keys(variables)
          .filter((key) => key.endsWith('.response'))
          .map((key) => {
            const keyName = key.split('.')[0];
            const formattedId = keyName
              .replace(/_/g, ' ')
              .toLowerCase()
              .replace(/parser/g, '');

            return {
              key: keyName,
              value: variables[key],
              id: formattedId,
              displayName: keyName
                .replace(/_/g, ' ')
                .toUpperCase()
                .replace(/PARSER/g, ''),
            };
          });
        setMessages(messages);
        const navIds = messages.map((msg) => msg.id);
        setNavElements(navIds);
      }
    }
    setIsLoading(false);
  }, [id, clientExecutions]);

  return (
    <ExecutionsStyles.RowContainer>
      {isLoading ? (
        <>
          <ExecutionsStyles.Skeleton>
            <Loader />
          </ExecutionsStyles.Skeleton>
        </>
      ) : (
        <>
          <ExecutionsStyles.ColumnsWrapper id="main-content">
            {messages.map((msj, index) => {
              return (
                <Fragment key={index}>
                  <div id={msj.id} className="execution-section">
                    <ExecutionsStyles.SectionTitle>
                      {msj.displayName}
                    </ExecutionsStyles.SectionTitle>
                    <ReactMarkdown>{msj.value}</ReactMarkdown>
                  </div>
                </Fragment>
              );
            })}
          </ExecutionsStyles.ColumnsWrapper>
          {navElements.length > 0 && (
            <ExecutionNavbar navElements={navElements} executionId={id} />
          )}
        </>
      )}
    </ExecutionsStyles.RowContainer>
  );
};

export default ExecutionsPage;
