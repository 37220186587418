import { useState } from 'react';
import { UsersDomain } from '../../../../modules/users/domain';

/**
 * @typedef {import('../../../../modules/users/domain/entities/users.entity').AuthDataEntity} AuthDataEntity
 */

const useAgentManagerLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const repository = new UsersDomain();
  /**
   * Función para iniciar sesión
   * @param {Object} data - Datos de inicio de sesión
   * @param {string} data.email - Correo electrónico del usuario
   * @param {string} data.password - Contraseña del usuario
   * @returns {Promise<AuthDataEntity>} - Usuario autenticado
   */
  const execute = async (data) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await repository.authenticate(data);
      return response;
    } catch (err) {
      const errorObj =
        err instanceof Error ? err : new Error('Error desconocido');
      setError(errorObj);
      throw errorObj;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    execute,
    isLoading,
    error,
  };
};

export default useAgentManagerLogin;
