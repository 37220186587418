import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import AgentsManmagerLayout from '../../components/Agents/Layout/index.js';
import ExecutePage from './ExecutePage';
import ExecutionsPage from './ExecutionPage.jsx';
import { ExecuteAgentProvider } from '../../contexts/ExecuteAgents.context.jsx';

const AgentRouter = () => {
  const { path } = useRouteMatch();

  return (
    <ExecuteAgentProvider>
      <AgentsManmagerLayout>
        <Switch>
          <Route exact path={`${path}`}>
            <Redirect to={`${path}/execute`} />
          </Route>
          <Route path={`${path}/execute`} component={ExecutePage} />
          <Route path={`${path}/execution/:id`} component={ExecutionsPage} />
          <Redirect to={`${path}`} />
        </Switch>
      </AgentsManmagerLayout>
    </ExecuteAgentProvider>
  );
};

export default AgentRouter;
