import { useState } from 'react';
import { AgentsRepository } from '../../../../modules/agents/data';

/**
 * @typedef {import('../../../../modules/agents/domain/entities/graphExecution.entity').ExecuteGraphDTO} ExecuteGraphDTO
 * @typedef {import('../../../../modules/agents/domain/entities/graphExecution.entity').ExecuteGraphResponseDTO} ExecuteGraphResponseDTO
 */


/**
 * @callback ExecuteAgentCallback
 * @param {ExecuteGraphDTO} request - Solicitud de ejecución de agente
 * @returns {Promise<ExecuteGraphResponseDTO>} - Resultado de la ejecución del agente
 */

/**
 * @typedef {Object} AgentExecutionReturn
 * @property {ExecuteAgentCallback} executeAgent - Función para ejecutar un agente
 * @property {boolean} loading - Estado de carga de la ejecución
 * @property {Error|null} error - Error de ejecución, si existe
 * @property {ExecuteGraphResponseDTO} result - Resultado de la ejecución
 */

/**
 * Hook para la ejecución de agentes
 * @returns {AgentExecutionReturn} Objeto con funciones y estado para la ejecución de agentes
 */
export const useAgentExecution = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);

  const repository = new AgentsRepository();

  /**
   * @type {ExecuteAgentCallback}
   */
  const executeAgent = async (request) => {
    setLoading(true);
    setError(null);

    try {
      const response = await repository.executeGraph(request);
      setResult(response);
      return response;
    } catch (err) {
      const errorObj =
        err instanceof Error ? err : new Error('Error desconocido');
      setError(errorObj);
      throw errorObj;
    } finally {
      setLoading(false);
    }
  };

  return {
    executeAgent,
    loading,
    error,
    result,
  };
};