import { IS_PRODUCTION } from "../config/env";

/**
 * @typedef {Object} CookieOptions
 * @property {string} [path] - The path where the cookie is accessible.
 * @property {number} [maxAge] - The maximum age of the cookie in seconds.
 * @property {boolean} [secure] - Indicates if the cookie should only be sent over secure connections.
 * @property {boolean} [httpOnly] - Indicates if the cookie is accessible only through the HTTP protocol.
 */

/**
 * Saves a cookie with the specified name and token value.
 *
 * @param {string} name - The name of the cookie.
 * @param {string} token - The value to be stored in the cookie.
 * @param {CookieOptions} [options] - Optional cookie configuration options.
 */
export const saveCookie = (
  name,
  token,
  options,
) => {
  document.cookie = `${name}=${token}; path=${options?.path || "/"}; max-age=${options?.maxAge || 60 * 60 * 24 * 7}; secure=${options?.secure || IS_PRODUCTION};`;
};

/**
 * Retrieves the value of the cookie with the specified name.
 *
 * @param {string} name - The name of the cookie to retrieve.
 * @returns {string | null} - The value of the cookie, or null if the cookie doesn't exist.
 */
export const getCookie = (name) => {
  if (typeof document === "undefined") {
    return null;
  }

  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    return parts.pop()?.split(";").shift() || null;
  }

  return null;
};

/**
 * Removes the cookie with the specified name.
 *
 * @param {string} name - The name of the cookie to remove.
 */
export const removeCookie = (name) => {
  document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
};
