import { useState } from 'react';
import { AgentsManagerDomain } from '../../../../modules/agents/domain';
/**
 * @typedef {import('../../../../modules/users/domain/entities/users.entity').ClientEntity} ClientEntity
 */

/**
 * @callback ExecuteCallback
 * @param {string} [name=''] - Name to search for clients
 * @returns {Promise<ClientEntity[]>} - List of clients
 */

/**
 * @typedef {Object} GetUserClientsReturn
 * @property {ExecuteCallback} executeCallback - Function to execute the search for clients
 * @property {ClientEntity[]} clients - List of clients
 * @property {boolean} loading - Loading state
 * @property {Error|null} error - Error, if exists
 */

/**
 * Hook to get clients by name
 * @returns {GetUserClientsReturn}
 */
export const useGetUserClients = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [clients, setClients] = useState([]);

  const repository = new AgentsManagerDomain();

  /**
   * @type {ExecuteCallback}
   */
  const executeCallback = async (name = '') => {
    setLoading(true);
    setError(null);

    try {
      const response = await repository.getClientsByName(name);
      if (!response) {
        setClients([]);
        return [];
      }

      setClients(response);
      return response;
    } catch (err) {
      const errorObj = err instanceof Error ? err : new Error('Unknown error');
      setError(errorObj);
      throw errorObj;
    } finally {
      setLoading(false);
    }
  };

  return {
    executeCallback,
    clients,
    loading,
    error,
  };
};

export default useGetUserClients;
