import { IFailureHandler } from '../../../../core/interface/IFailureHandler';
import { UsersPorts } from '../ports/users.ports';
import { IUseCase } from '../../../../core/interface/IUseCase';
import { saveCookie } from '../../../../core/cookies/cookiesUtils';

/**
 * @typedef {import('../../domain/entities/users.entity').AuthDataEntity} AuthDataEntity
 */

export class LoginAccountUseCase extends IUseCase {
  userRepository;
  failureHandler;
  /**
   * Constructor for the LoginAccount use case.
   * @param {UsersPorts} userRepository - The repository for user operations.
   * @param {IFailureHandler} failureHandler - The failure handler for error management.
   */
  constructor(userRepository, failureHandler) {
    super();
    this.userRepository = userRepository;
    this.failureHandler = failureHandler;
  }

  /**
   * @inheritdoc
   * @param {Object} data - The parameters for the login operation.
   * @param {string} data.email - The email of the user.
   * @param {string} data.password - The password of the user.
   * @returns {Promise<AuthDataEntity>} - A promise that resolves to the user data if login is successful.
   * @throws {Error} - If the login operation fails.
   */
  async execute(data) {
    try {
      const user = await this.userRepository.authenticate(data);
      if (user && user.client) {
        saveCookie('__auth.session-clientData', JSON.stringify(user.client));
      }
      if (user && user.accessToken) {
        saveCookie('__auth.session-token', user.accessToken, {
          maxAge: 60 * 60 * 24 * 2, // 2 days
        });
      }
      return user;
    } catch (error) {
      return String(error);
    }
  }
}
