import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import { device } from '../style/device';
import { isMobile } from 'react-device-detect';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import '../style/footer.css';
import { useTranslation } from 'react-i18next';

const FooterStyles = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 20px;
  background-color: #2240d9;
  background-color: ${({ alternative }) =>
    alternative ? '#FFA800' : '#2240d9'};

  .MuiSvgIcon-root {
    color: white !important;
  }
  p {
    font-size: 14px;
    margin-top: 0;
  }
  h5 {
    text-align: center;
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 18px;
  }
  li {
    padding: 5px 0;
  }
  .site {
    width: 90%;
  }
  .contact-us {
    a {
      text-decoration: underline;
    }
  }
  .title-web {
    font-size: 20px;
  }

  @media ${device.tablet} {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
    align-content: center;
    width: 100%;
    padding: 8% 8% 5%;
    box-sizing: border-box;
    h3 {
      display: block;
      margin-top: 0;
      font-size: 32px;
    }
    h5 {
      text-align: left;
      margin: 0;
    }
    .title {
      padding-bottom: 30px;
      letter-spacing: 2px;
    }
    form {
      display: block;
    }
    img {
      display: inline;
      width: 80%;
    }
    ul {
      padding: 0;
      margin: 0;
    }
    li {
      font-weight: 400;
      font-size: 20px;
      line-height: 41.38px;
      color: white;
      list-style-type: none;
    }
    input {
      width: 100%;
      background-color: transparent;
      background: url('./Group 222.png') no-repeat;
      background-position: 100%;
      border: none;
      opacity: 0.3;
      outline: none;
      color: #fff;
      font-size: 20px;
      line-height: 45px;
      border-bottom: 1px solid #fff;
    }
    .site {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-content: space-between;
      width: 100%;
      padding-bottom: 20px;
      p {
        line-height: 25px;
      }
    }

    .column-footer {
      max-width: 192px;
    }

    .contact-us {
      display: flex;
    }

    .newsletter {
      position: absolute;
      right: 8%;
      bottom: 10%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 353px;
      img {
        width: 162px;
      }
    }
  }
`;

function Footer() {
  const { t } = useTranslation(['footer']);

  return (
    <>
      {isMobile ? (
        <FooterStyles alternative={window.location.pathname === '/v2'}>
          <div className='site'>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography>
                  <h5 className='title'>{t('c3.title')}</h5>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div style={{ alignItems: 'center' }} className='contact-us'>
                    <ul>
                      <li>
                        <a
                          style={{
                            textDecoration: 'underline',
                            fontSize: '14px',
                          }}
                          href='https://mail.google.com/mail/?view=cm&fs=1&to=contact@therocketcode.com'
                          target='blank'
                        >
                          contact@therocketcode.com
                        </a>
                      </li>
                      <li>
                        <p style={{ fontSize: '14px', margin: '5px 0px' }}>
                          {/* {t("c3.l1")} */}
                          {/* <br /> */}
                          <a
                            href='https://wa.me/+5215580833336'
                            target='_blank'
                            rel='noreferrer'
                          >
                            +52 1 55 8083 3336
                          </a>
                        </p>
                      </li>
                      {/* <li>
                        <p style={{ fontSize: "14px", margin: "5px 0px" }}>
                          {t("c3.l2")} <br />
                          <a
                            href="https://wa.me/+5215580833336"
                            target="_blank"
                            rel="noreferrer"
                          >
                            55 8083 3336
                          </a>
                        </p>
                      </li> */}
                    </ul>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Typography>
              <Link to='/v1/meet-us'>
                <h5
                  className='title'
                  style={{ textAlign: 'left', margin: '16px 0' }}
                >
                  {t('c1.l3')}
                </h5>
              </Link>
            </Typography>
            <Typography>
              <Link to='/v1/new-hiring'>
                <h5
                  className='title'
                  style={{
                    textAlign: 'left',
                    margin: '16px 0',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  {t('c1.l2')}
                </h5>
              </Link>
            </Typography>
            <Typography>
              <h5
                className='title'
                style={{
                  textAlign: 'left',
                  margin: '16px 0',
                  textDecoration: 'underline',
                }}
              >
                talent@therocketcode.com
              </h5>
            </Typography>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography>
                  <h5 className='title'>{t('c2.title')}</h5>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div style={{ width: '45%' }}>
                    <Typography>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          paddingTop: '20px',
                        }}
                      >
                        <img
                          src='/v1/location.svg'
                          alt='Location'
                          style={{
                            height: '24px',
                            width: '24px',
                            paddingRight: '5px',
                          }}
                        />
                        <h5 style={{ fontSize: '16px' }}>México City</h5>
                      </div>
                      <p style={{ fontSize: '14px' }}>
                        Plaza ARTZ <br />
                        Anillo Periférico
                        <br />
                        Jardines del Pedregal <br />
                        01900 Álvaro Obregón
                      </p>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          src='/v1/location.svg'
                          alt='Location'
                          style={{
                            height: '24px',
                            width: '24px',
                            paddingRight: '5px',
                          }}
                        />
                        <h5 style={{ fontSize: '16px' }}>Querétaro, México</h5>
                      </div>
                      <p style={{ fontSize: '14px' }}>
                        Av. Paseo Monte Miranda 15 <br />
                        Int. 812 <br />
                        Colonia Miranda <br />
                        76240 El Marques, Querétaro.
                      </p>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          src='/v1/location.svg'
                          alt='Location'
                          style={{
                            height: '24px',
                            width: '24px',
                            paddingRight: '5px',
                          }}
                        />
                        <h5 style={{ fontSize: '16px' }}>San Francisco</h5>
                      </div>
                      <p style={{ fontSize: '14px' }}>
                        Salesforce Tower, <br />
                        415 Mission Street <br />
                        San Francisco, CA 94105
                      </p>
                    </Typography>
                  </div>
                  <div style={{ width: '45%' }}>
                    <Typography>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          src='/v1/location.svg'
                          alt='Location'
                          style={{
                            height: '24px',
                            width: '24px',
                            paddingRight: '5px',
                          }}
                        />
                        <h5 style={{ fontSize: '16px' }}>Miami</h5>
                      </div>
                      <p style={{ fontSize: '14px' }}>
                        1200 Ponce de Leon, Coral Gables, <br />
                        Miami, FL 33134 <br />
                        USA
                      </p>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          src='/v1/location.svg'
                          alt='Location'
                          style={{
                            height: '24px',
                            width: '24px',
                            paddingRight: '5px',
                          }}
                        />
                        <h5 style={{ fontSize: '16px' }}>Colombia</h5>
                      </div>
                      <p style={{ fontSize: '14px' }}>
                        Centro Empresarial Colpatria, <br />
                        Cl. 127A #54a-45 <br />
                        Bogotá, Colombia
                      </p>
                    </Typography>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <div
            style={{
              display: 'flex',
              width: '90%',
              padding: '20px 0',
              margin: '20px 0',
            }}
          >
            <hr
              style={{
                border: 'none',
                borderTop: '1px solid #FFFFFF',
                width: '25%',
              }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '50%',
              }}
            >
              <a
                href='https://www.facebook.com/therocketcode'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  style={{ height: '24px' }}
                  src='/v1/Facebook.svg'
                  alt='Facebook'
                />
              </a>
              <a
                href='https://www.instagram.com/therocketcode?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=='
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  style={{ height: '24px', paddingLeft: '30px' }}
                  src='/v1/Instagram.svg'
                  alt='Instagram'
                />
              </a>
              <a
                href='https://www.linkedin.com/company/the-rocket-code/about/?viewAsMember=true'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  style={{ height: '24px', paddingLeft: '30px' }}
                  src='/v1/Linkedin.svg'
                  alt='Linkedin'
                />
              </a>
            </div>
            <hr
              style={{
                border: 'none',
                borderTop: '1px solid #FFFFFF',
                width: '25%',
              }}
            />
          </div>
          <img src='/v1/LogoRocket.svg' alt='Logo Rocket Code' />
          <div
            style={{ width: '100%', textAlign: 'center', marginTop: '30px' }}
          >
            <p
              style={{
                fontSize: '15px',
                margin: '10px 0 0',
              }}
            >
              © Copyright {new Date().getFullYear()} Rocket Code.{' '}
            </p>
            <p style={{ fontSize: '15px', margin: 0 }}>
              {t('copyright.copyright')} |{' '}
              <a
                href='https://therocketcode.com/v1/privacy'
                style={{
                  textDecoration: 'underline',
                }}
              >
                {t('copyright.pp')}
              </a>
            </p>
          </div>
        </FooterStyles>
      ) : (
        <FooterStyles alternative={window.location.pathname === '/v2'}>
          <div className='site'>
            <div>
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: '40px' }}>
                  <img
                    src='/v1/iconRocket.svg'
                    alt='Rocket Code Icon'
                    style={{ width: '50px' }}
                  />
                </div>
                <div className='column-footer'>
                  <h5 className='title-web' style={{ paddingBottom: '16px' }}>
                    {t('c3.title')}
                  </h5>
                  <a
                    style={{ textDecoration: 'underline' }}
                    href='https://mail.google.com/mail/?view=cm&fs=1&to=contact@therocketcode.com'
                    target='blank'
                  >
                    contact@therocketcode.com
                  </a>
                  <p>
                    <a
                      href='https://wa.me/+5215580833336'
                      target='_blank'
                      rel='noreferrer'
                    >
                      +52 1 55 8083 3336
                    </a>
                  </p>
                  <ul>
                    <li>
                      <Link to='/v1/meet-us'>
                        <h5
                          className='title-web'
                          style={{ paddingBottom: '16px' }}
                        >
                          {t('c1.l3')}
                        </h5>
                      </Link>
                    </li>
                    <li>
                      <Link to='/v1/new-hiring'>
                        <h5
                          className='title-web'
                          style={{
                            paddingBottom: '16px',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                        >
                          {t('c1.l2')}
                        </h5>
                      </Link>
                    </li>
                  </ul>
                  <a
                    style={{ textDecoration: 'underline' }}
                    href='https://mail.google.com/mail/?view=cm&fs=1&to=talent@therocketcode.com'
                    target='blank'
                  >
                    talent@therocketcode.com
                  </a>
                </div>
              </div>
            </div>
            <div className='column-footer'>
              <h5 className='title-web' style={{ paddingBottom: '16px' }}>
                {t('c2.title')}
              </h5>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src='/v1/location.svg'
                  alt='Location'
                  style={{ height: '24px', width: '24px', paddingRight: '5px' }}
                />
                <h5>México City</h5>
              </div>
              <p>
                Plaza ARTZ <br />
                Anillo Periférico
                <br />
                Jardines del Pedregal <br />
                01900 Álvaro Obregón
              </p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src='/v1/location.svg'
                  alt='Location'
                  style={{ height: '24px', width: '24px', paddingRight: '5px' }}
                />
                <h5>Querétaro, México</h5>
              </div>
              <p>
                Av. Paseo Monte Miranda 15 <br />
                Int. 812 <br />
                Colonia Miranda <br />
                76240 El Marques, Querétaro.
              </p>
            </div>
            <div className='column-footer'>
              <h5
                className='title-web'
                style={{ paddingBottom: '16px', color: 'transparent' }}
              >
                {'Locations'}
              </h5>
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src='/v1/location.svg'
                    alt='Location'
                    style={{
                      height: '24px',
                      width: '24px',
                      paddingRight: '5px',
                    }}
                  />
                  <h5>San Francisco</h5>
                </div>
                <p>
                  Salesforce Tower, <br />
                  415 Mission Street <br />
                  San Francisco, CA 94105
                </p>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src='/v1/location.svg'
                    alt='Location'
                    style={{
                      height: '24px',
                      width: '24px',
                      paddingRight: '5px',
                    }}
                  />
                  <h5>Miami</h5>
                </div>
                <p>
                  1200 Ponce de Leon, <br />
                  Coral Gables <br />
                  Miami, FL 33134 <br />
                  USA
                </p>
              </div>
            </div>
            <div className='column-footer'>
              <h5
                className='title-web'
                style={{ paddingBottom: '16px', color: 'transparent' }}
              >
                {'Locations'}
              </h5>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src='/v1/location.svg'
                  alt='Location'
                  style={{
                    height: '24px',
                    width: '24px',
                    paddingRight: '5px',
                  }}
                />
                <h5>Colombia</h5>
              </div>
              <p>
                Centro Empresarial Colpatria, <br />
                Cl. 127A #54a-45 <br />
                Bogotá, Colombia
              </p>
            </div>
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            <hr
              style={{
                border: 'none',
                borderTop: '1px solid #FFFFFF',
                width: '35%',
              }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '30%',
              }}
            >
              <a
                href='https://www.facebook.com/therocketcode'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  style={{ height: '24px' }}
                  src='/v1/Facebook.svg'
                  alt='Facebook'
                />
              </a>
              <a
                href='https://www.instagram.com/therocketcode?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=='
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  style={{ height: '24px', paddingLeft: '30px' }}
                  src='/v1/Instagram.svg'
                  alt='Instagram'
                />
              </a>
              <a
                href='https://www.linkedin.com/company/the-rocket-code/about/?viewAsMember=true'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  style={{ height: '24px', paddingLeft: '30px' }}
                  src='/v1/Linkedin.svg'
                  alt='Linkedin'
                />
              </a>
            </div>
            <hr
              style={{
                border: 'none',
                borderTop: '1px solid #FFFFFF',
                width: '35%',
              }}
            />
          </div>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <img
              src='/v1/new-logo-2.svg'
              alt='Rocket Code'
              style={{
                width: '220px',
                marginLeft: '20px',
                maxWidth: '80%',
                marginBottom: '20px',
                marginTop: '20px',
              }}
            />
          </div>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <p
              style={{
                fontSize: '15px',
                margin: '10px 0 0',
              }}
            >
              © Copyright {new Date().getFullYear()} Rocket Code.{' '}
            </p>
            <p style={{ fontSize: '15px', margin: 0 }}>
              {t('copyright.copyright')} |{' '}
              <a
                href='https://therocketcode.com/v1/privacy'
                style={{
                  textDecoration: 'underline',
                }}
              >
                {t('copyright.pp')}
              </a>
            </p>
          </div>
          {/* <ul>
            <li>
              <a
                style={{ textDecoration: "underline" }}
                href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@therocketcode.com"
                target="blank"
              >
                contact@therocketcode.com
              </a>
            </li>
            <li>
              <p>
                <a
                  href="https://wa.me/+5215580833336"
                  target="_blank"
                  rel="noreferrer"
                >
                  +52 1 55 8083 3336
                </a>
              </p>
            </li>
          </ul> */}
        </FooterStyles>
      )}
    </>
  );
}

export default Footer;
