/**
 * @typedef {import('../entities/angets.entity').AgentEntity} AgentEntity
 * @typedef {import('../entities/angets.entity').AgentFlow} AgentFlow
 * @typedef {import('../../../users/domain/entities/users.entity').ClientEntity} ClientEntity
 */

/**
 * @typedef {Object} GetAgentsResults
 * @property {AgentEntity[]} agents - Lista de agentes
 * @property {number} pagination - Número de páginas
 */

/**
 * @class AgentsPorts
 * @description Interfaz que define los puertos para operaciones relacionadas con agentes.
 * Esta clase sirve como contrato para las implementaciones de repositorios de agentes.
 */
export class AgentsPorts {
  /**
   * Gets a list of agents with pagination
   * @param {Object} dataGetAgents - Search params
   * @param {number} [dataGetAgents.search] - Search param to filter agents
   * @param {number} [dataGetAgents.skip] - Number of agents to skip
   * @param {string} [dataGetAgents.entriesPerPage] - Number of agents to return
   * @returns {Promise<GetAgentsResults>} Response from the agent manager
   * @throws {Error} Failure to implement the method
   */
  async getAgents(dataGetAgents) {
    throw new Error('Method getAgents not implemented');
  }

  /**
   * Obtiene un agente específico incluyendo sus flujos asociados
   * @param {string} id - Identificador único del agente
   * @returns {Promise<AgentFlow[]>} Entidad del agente con sus flujos
   * @throws {Error} Si el método no está implementado o falla
   */
  async getAgentWithFlows(id) {
    throw new Error('Method getAgentWithFlows not implemented');
  }

  /**
   * Get user clients by name
   * @param {Object} data - Data to search clients
   * @param {string} data.name - Name of the client
   * @returns {Promise<ClientEntity[]>} Response from the agent manager
   * @throws {Error} Failure to implement the method
   */
  async getClientsByName(data) {
    throw new Error('Method getClientsByName not implemented');
  }

  /**
   * Ejecuta el grafo de flujo de un agente
   * @param {Object} executeDto - Datos para la ejecución del grafo
   * @param {string} executeDto.agentId - ID del agente
   * @param {string} executeDto.flowId - ID del flujo
   * @param {Object} executeDto.input - Datos de entrada para la ejecución
   * @returns {Promise<Object>} Resultado de la ejecución del grafo
   * @throws {Error} Si el método no está implementado o falla
   */
  async executeGraph(executeDto) {
    throw new Error('Method executeGraph not implemented');
  }
}