export const URL_SWARM_V1 = process.env.REACT_APP_API_SWARM_URL_V1;
export const URL_AUTOSALES_V1 = process.env.REACT_APP_API_SWARM_URL_V2;
export const URL_ASSISTANT_BOT = process.env.REACT_APP_ASSISTANT_BOT_URL;

// const token = localStorage.getItem('token');

export const revalidateSession = async ({ version = 'autosales' }) => {
  try {
    const url = version === 'swarm' ? URL_SWARM_V1 : URL_AUTOSALES_V1;
    const token = localStorage.getItem('token');
    const response = await fetch(`${url}/api/swarm/revalidateSession`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error al revalidar la sesión', error);
    return { error: true };
  }
};

export const login = async ({ version = 'autosales', email, password }) => {
  try {
    const url = version === 'swarm' ? URL_SWARM_V1 : URL_AUTOSALES_V1;
    console.log({ url });
    const response = await fetch(`${url}/api/swarm/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });
    console.log({ response });
    const data = await response.json();
    if (data.token) {
      localStorage.setItem('token', data.token);
    }
    return data;
  } catch (error) {
    console.error('Error al iniciar sesión', error);
  }
};

export const registerAccount = async ({
  version = 'autosales',
  email,
  name,
  password,
}) => {
  try {
    const url = version === 'swarm' ? URL_SWARM_V1 : URL_AUTOSALES_V1;
    const response = await fetch(`${url}/api/swarm/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password, name }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error al registrar la cuenta', error);
  }
};

export const fechHistoryFromUserCredentials = async ({
  version = 'autosales',
}) => {
  try {
    const url = version === 'swarm' ? URL_SWARM_V1 : URL_AUTOSALES_V1;
    const token = localStorage.getItem('token');
    const response = await fetch(`${url}/api/swarm/history/chats`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data.data || [];
  } catch (error) {
    console.error('Error al obtener el historial', error);
  }
};

export const fetchHistory = async ({ version = 'autosales', threadId }) => {
  try {
    const url = version === 'swarm' ? URL_SWARM_V1 : URL_AUTOSALES_V1;
    const token = localStorage.getItem('token');
    const response = await fetch(
      `${url}/api/swarm/history/messages/${threadId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data.data || [];
  } catch (error) {
    console.error('Error al obtener el historial', error);
  }
};

export const fetchHistorySanitized = async ({
  version = 'autosales',
  threadId,
}) => {
  try {
    const url = version === 'swarm' ? URL_SWARM_V1 : URL_AUTOSALES_V1;
    const token = localStorage.getItem('token');
    const response = await fetch(
      `${url}/api/swarm/history/messages/${threadId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();

    return data.data || [];
  } catch (error) {
    console.error('Error al obtener el historial', error);
    return [];
  }
};

export const sendMessageToAssistantBot = async ({
  version = 'autosales',
  userMessage,
  idSession,
}) => {
  try {
    const url = version === 'swarm' ? URL_SWARM_V1 : URL_AUTOSALES_V1;
    const response = await fetch(`${URL_ASSISTANT_BOT}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userMessage, idSession }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error al enviar el mensaje al bot', error);
  }
};

/**
 * Inicia una conversación.
 *
 * @param {Object} params - Los parámetros para iniciar la conversación.
 * @param {Object} params.task - La tarea asociada a la conversación.
 * @param {Object} [params.chatConfig={}] - La configuración del chat.
 * @param {string} params.conversation_id - El ID de la conversación.
 *
 * @returns {Promise<{
 *   conversation_id: string,
 *   messages?: {
 *     type: 'ai' | 'human' | 'assistant' | 'user',
 *     content: string,
 *     agent_name?: string,
 *     agent_question?: string,
 *     message_tittle?: string,
 *   }[],
 *  ai_message?: string,
 *  DDBB_CONVERSATION_ID?: string,
 * }>} La respuesta de la API.
 */
export const startConversation = async ({
  version = 'autosales',
  task,
  chatConfig = {},
  conversation_id,
}) => {
  try {
    const url = version === 'swarm' ? URL_SWARM_V1 : URL_AUTOSALES_V1;
    const token = localStorage.getItem('token');
    const response = await fetch(`${url}/api/swarm/conversations`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ chatConfig, task, conversation_id }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error al obtener el historial', error);
    return [];
  }
};

export const fetchHistoryInOrderToRender = async ({
  version = 'autosales',
  threadId,
}) => {
  try {
    const url = version === 'swarm' ? URL_SWARM_V1 : URL_AUTOSALES_V1;
    const token = localStorage.getItem('token');
    const response = await fetch(
      `${url}/api/swarm/history/messages/${threadId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();

    if (data.data.length === 0)
      return console.log('No hay mensajes en el historial');

    // const { chat_name } = data.data[0];
    const { conversation_name } = data;

    const messagesFomDDBB = data.data
      .sort((a, b) => new Date(a.message_order) - new Date(b.message_order))
      .map((msg) => {
        return {
          message: msg.message,
          name: msg.name,
          message_name: msg.message_name,
          response: msg.response,
        };
      });

    return { messages: messagesFomDDBB || [], conversation_name };
  } catch (error) {
    console.error('Error al obtener el historial', error);
    return { messages: [], conversation_name: '' };
  }
};

export const fetchThreads = async ({ version = 'autosales' }) => {
  try {
    const url = version === 'swarm' ? URL_SWARM_V1 : URL_AUTOSALES_V1;
    const token = localStorage.getItem('token');
    const response = await fetch(`${url}/api/swarm/history/names`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data.data || [];
  } catch (error) {
    console.error('Error al obtener los hilos', error);
  }
};

export const renameHistoryChat = async ({
  version = 'autosales',
  threadId,
  name,
}) => {
  try {
    const url = version === 'swarm' ? URL_SWARM_V1 : URL_AUTOSALES_V1;
    const token = localStorage.getItem('token');
    await fetch(`${url}/api/swarm/history/rename/${threadId}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: {
        name,
      },
    });
  } catch (error) {
    console.error('Error al renombrar el chat', error);
  }
};

export const deleteHistoryChat = async ({
  version = 'autosales',
  threadId,
}) => {
  try {
    const url = version === 'swarm' ? URL_SWARM_V1 : URL_AUTOSALES_V1;
    const token = localStorage.getItem('token');
    await fetch(`${url}/api/swarm/history/delete/${threadId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    console.error('Error al eliminar el chat', error);
  }
};

export const handlerLikeMessaage = async ({
  version = 'autosales',
  messages,
  agent_name,
  action,
  threadId,
}) => {
  try {
    const url = version === 'swarm' ? URL_SWARM_V1 : URL_AUTOSALES_V1;
    const token = localStorage.getItem('token');
    await fetch(`${url}/api/swarm/feedback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        messages,
        agent_name,
        threadId,
        action,
      }),
    });
  } catch (error) {
    console.error('Error al enviar el like', error);
  }
};

export const stopStreaming = async ({ version = 'autosales', threadId }) => {
  try {
    const url = version === 'swarm' ? URL_SWARM_V1 : URL_AUTOSALES_V1;
    const token = localStorage.getItem('token');
    await fetch(`${url}/api/swarm/stop/streaming/?threadId=${threadId}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    console.error('Error al detener el streaming', error);
  }
};

// #region Download PDF
export const downloadInPDF = async ({ version = 'autosales', threadId }) => {
  try {
    const url = version === 'swarm' ? URL_SWARM_V1 : URL_AUTOSALES_V1;
    const response = await fetch(
      `${url}/api/swarm/download/pdf?threadId=${threadId}`
    );
    const pdfBuffer = await response.arrayBuffer();

    const blob = new Blob([pdfBuffer], { type: 'application/pdf' });
    const downloadURL = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = 'proposal.pdf';
    link.click();

    URL.revokeObjectURL(downloadURL);

    link.remove();
    return { error: false, message: 'PDF descargado correctamente' };
  } catch (error) {
    console.error('Error al descargar el PDF', error);
    return { error: true, message: 'Error al descargar el PDF' };
  }
};
// #endregion Download PDF

// #region Download PPTX
export const downloadPPTX = async ({ version = 'autosales', threadId }) => {
  try {
    const url = version === 'swarm' ? URL_SWARM_V1 : URL_AUTOSALES_V1;
    const token = localStorage.getItem('token');
    const response = await fetch(
      `${url}/api/swarm/download/pptx?threadId=${threadId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseData = await response.json();

    const { presentationURL } = responseData;

    window.open(presentationURL, '_blank');

    // const link = document.createElement('a');
    // link.href = presentationURL;
    // link.target = '_blank';
    // link.click();

    // link.remove();

    return {
      error: false,
      message: 'PPTX descargado correctamente',
      url: presentationURL,
    };
  } catch (error) {
    console.error('Error al descargar el PPTX', error);
    return { error: true, message: 'Error al descargar el PPTX', url: null };
  }
};
// #endregion Download PPTX

// #region Ask AI Functions

export const rewriteMessage = async ({
  version = 'swarm',
  prompt,
  message,
  threadId,
}) => {
  try {
    const url = version === 'swarm' ? URL_SWARM_V1 : URL_AUTOSALES_V1;
    const token = localStorage.getItem('token');
    const response = await fetch(`${url}/api/swarm/rewrite-message`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ prompt, message, threadId }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error al reescribir el mensaje', error);
  }
};

export const removeSessionStorageItem = ({ item }) => {
  if (sessionStorage.getItem(item)) {
    sessionStorage.removeItem(item);
  }
};
