import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ModalOverlay, ModalContainer, CloseButton } from './Modal.styles';

/**
 * Modal component with customizable behavior
 *
 * @param {Object} props - Component props
 * @param {boolean} props.isModalOpen - Controls if the modal is visible
 * @param {Function} props.onClose - Function to call when modal should close
 * @param {boolean} [props.closable=true] - Whether the modal can be closed by user actions
 * @param {React.ReactNode} props.children - Content to render inside the modal
 * @param {string} [props.width] - Custom width for the modal container
 * @param {Object} [props.overlayStyle] - Additional styles for the overlay
 * @param {Object} [props.containerStyle] - Additional styles for the container
 */
const Modal = ({
  isModalOpen,
  onClose,
  closable = true,
  children,
  width,
  overlayStyle,
  containerStyle,
  ...rest
}) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleEscKey = (event) => {
      if (closable && event.key === 'Escape') {
        onClose();
      }
    };

    const handleClickOutside = (event) => {
      if (
        closable &&
        modalRef.current &&
        !modalRef.current.contains(event.target)
      ) {
        onClose();
      }
    };

    if (isModalOpen) {
      document.addEventListener('keydown', handleEscKey);
      document.addEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEscKey);
      document.removeEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = '';
    };
  }, [isModalOpen, onClose, closable]);

  if (!isModalOpen) return null;

  return (
    <ModalOverlay isOpen={isModalOpen} style={overlayStyle}>
      <ModalContainer
        ref={modalRef}
        isOpen={isModalOpen}
        style={{ width, ...containerStyle }}
        {...rest}
      >
        {closable && (
          <CloseButton onClick={onClose} aria-label="Close modal">
            ×
          </CloseButton>
        )}
        {children}
      </ModalContainer>
    </ModalOverlay>
  );
};

Modal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  closable: PropTypes.bool,
  children: PropTypes.node,
  width: PropTypes.string,
  overlayStyle: PropTypes.object,
  containerStyle: PropTypes.object,
};

export default Modal;
