import { getCookie } from "../cookies/cookiesUtils";
import axios from "axios";

export const createApiClient = (baseURL) => {
  const apiClient = axios.create({
    baseURL,
    headers: {},
  });

  // Add a request interceptor to grab fresh cookie values
  apiClient.interceptors.request.use((config) => {
    const token = getCookie("__auth.session-token");
    const clientData = getCookie("__auth.session-clientData");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    if (clientData) {
      config.headers["X-Client-Data"] = clientData;
    }

    // Only set Content-Type for JSON requests, not FormData
    if (!(config.data instanceof FormData)) {
      config.headers["Content-Type"] = "application/json";
    }

    return config;
  });

  return apiClient;
};
