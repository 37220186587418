import { IFailureHandler } from '../../../core/interface/IFailureHandler';
import { UserRepository } from '../data';
import { LoginAccountUseCase } from './useCases/loginAccount.useCase';

/**
 * @typedef {import('../domain/entities/users.entity').AuthDataEntity} AuthDataEntity
 */

export class UsersDomain {
  #userRepository = new UserRepository();
  #failureHandler;
  #loginUserRepository;

  constructor() {
    this.#failureHandler = new IFailureHandler();
    this.#userRepository = new UserRepository();
    this.#loginUserRepository = new LoginAccountUseCase(
      this.#userRepository,
      this.#failureHandler
    );
  }

  /**
   * @inheritdoc
   * @param {Object} data - The parameters to login
   * @param {string} data.email - The email of the user to login
   * @param {string} data.password - The password of the user to login
   * @returns {Promise<AuthDataEntity>} - The user logged in
   */
  authenticate = async (data) => {
    return await this.#loginUserRepository.execute(data);
  };
}
