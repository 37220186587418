import { IFailureHandler } from '../../../../core/interface/IFailureHandler';
import { AgentsPorts } from '../ports/agents.ports';
import { IUseCase } from '../../../../core/interface/IUseCase';

/**
 * @typedef {import('../entities/angets.entity').AgentEntity} AgentEntity
 */

/**
 * @typedef {Object} Results
 * @property {AgentEntity[]} agents - The list of agents
 * @property {number} pagination - Number of pages
 */

export class GetAgentUseCase extends IUseCase {
  agentsRepository;
  failureHandler;

  /**
   * Constructor for the GetAgentUseCase class
   * @param {AgentsPorts} agentsRepository - The repository to get agents
   * @param {IFailureHandler} failureHandler - The failure handler to handle errors
   */
  constructor(agentsRepository, failureHandler) {
    super();
    this.agentsRepository = agentsRepository;
    this.failureHandler = failureHandler;
  }
  /**
   * @inheritdoc
   * @param {Object} params - The parameters to get agents
   * @param {string} [params.name] - The name of the agent to search for
   * @param {number} [params.skip] - The number of agents to skip
   * @param {number} [params.entriesPerPage] - The number of agents to return per page
   * @returns {Promise<Results>} - The result of the search
   * @throws {Error} - If an error occurs while getting agents
   */
  async execute(params) {
    try {
      const agents = await this.agentsRepository.getAgents(params);
      return agents;
    } catch (error) {
      this.failureHandler.logAndMapExceptionToFailure(error);
      throw error;
    }
  }
}
