import styled, { keyframes } from 'styled-components';

const load = keyframes`
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
`;

const LoaderSkeleton = styled.div`
  position: relative;
  width: 100%;
  height: 18px;
  background-color: #9a9a9a;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 8px;
  transition: all 0.3s ease;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    background: linear-gradient(
      to right,
      transparent,
      rgba(255, 255, 255, 0.7),
      transparent
    );
    animation: ${load} 2s infinite;
  }
`;

const Reciente = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 28.49px;
`;

const ProposalBuilder = styled.div`
  display: flex;
  padding: var(--spacing-md, 8px) var(--spacing-lg, 12px);
  align-items: center;
  gap: var(--spacing-md, 8px);
  align-self: stretch;
  color: var(--NEUTRAL-WHITE, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
`;

const PropuestaCliente = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 12px;
  color: #fff;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  font-size: 16px;
  line-height: 28.49px;
  font-weight: 600;
  cursor: pointer;
`;

const InputChangeName = styled.input`
  display: none;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  line-height: 28.49px;
  font-weight: 600;
  cursor: pointer;
  z-index: 1;
`;

const IconsWrapper = styled.div`
  display: none;
  position: absolute;
  right: 14px;
  align-items: center;
  min-width: 36px;
  box-sizing: border-box;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 80%;

  .delete-icon,
  .edit-icon {
    min-width: 18px;
  }

  .delete-icon {
    cursor: pointer;
    color: #d1d5db;
    transition: all 0.5s ease;

    &:hover {
      color: #c1494a;
    }
  }

  .edit-icon {
    cursor: pointer;
    color: #d1d5db;
    transition: all 0.5s ease;

    &:hover {
      color: #00aa58;
    }
  }
`;

const PropuestaClienteWrapper = styled.div`
  position: relative;
  display: flex;
  align-self: stretch;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 14px;
  min-height: 40px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  overflow: hidden;

  &:hover {
    background-color: #1d4ed8;

    ${IconsWrapper} {
      display: flex;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const RoundedDot = styled.span`
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #17b26a;
`;

const RecienteParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px 0px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    height: 24px;
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0px;
  padding: 0px;
  box-sizing: border-box;
  overflow: hidden;
  height: 100%;
  background: #0e1d39;

  @media screen and (max-width: 642px) {
    display: none;
    padding-top: 0px;
    padding-bottom: 0px;
    box-sizing: border-box;
  }
`;

const SidebarNav = styled.nav`
  padding-top: var(--spacing-4xl, 32px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  width: 80px;
  align-self: stretch;
  border-right: 1px solid var(--Colors-Border-border-secondary, #eaecf0);
`;

const NavElement = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: var(--spacing-md, 8px);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: var(--Colors-Text-text-primary, #1f2937);
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;

  &:hover {
    background-color: var(--Colors-Background-background-secondary, #1d4ed8);
    color: var(--Colors-Text-text-primary, #fff);
  }
`;

const SidebarRoot = styled.div`
  align-self: stretch;
  background-color: #0e1d39;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 24px;
  box-sizing: border-box;
  gap: 24px 0px;
  width: 280px;
  text-align: left;
  font-size: 16px;
  color: #fff;
  overflow-y: auto;

  @media screen and (max-width: 642px) {
    display: none;
    padding-top: 0px;
    padding-bottom: 0px;
    box-sizing: border-box;
  }
`;

export {
  LoaderSkeleton,
  Reciente,
  ProposalBuilder,
  PropuestaCliente,
  InputChangeName,
  IconsWrapper,
  PropuestaClienteWrapper,
  RoundedDot,
  RecienteParent,
  SidebarWrapper,
  SidebarNav,
  NavElement,
  SidebarRoot,
};
