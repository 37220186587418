export class IFailureHandler {
  mapErrorToException(error) {
    throw new Error('Method not implemented.');
  }

  logAndMapExceptionToFailure(e) {
    throw new Error('Method not implemented.');
  }

  async handleServerFailure(failure, customErrorMessages) {
    throw new Error('Method not implemented.');
  }
}
