import { Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import { SwarmStyles } from './styles/SwarmStyles';
import Swarm from './pages/Swarm';
import ChatPage from './pages/Chat';
import Logout from './pages/Auth/Logout';
import LoginPage from './pages/Auth/LoginForm';
import SwarmLayout from './components/Layuout';
import { SwarmProvider } from './contexts/Swarm.context';
import useVersionExtractor from './hooks/useExtractVersionFromPath';
import AgentRouter from './pages/Agents/agents.router';
import ProposalPage from './pages/Proposal';

const RouteWithLayout = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <SwarmLayout>
        <Component {...props} />
      </SwarmLayout>
    )}
  />
);

const SwarmRoutes = ({ match }) => {
  const version = useVersionExtractor();
  return (
    <SwarmProvider>
      <SwarmStyles />
      <Switch>
        <RouteWithLayout exact path={`${match.path}/`} component={Swarm} />
        <RouteWithLayout
          exact
          path={`${match.path}/login`}
          component={LoginPage}
        />
        <RouteWithLayout
          exact
          path={`${match.path}/logout`}
          component={Logout}
        />
        <RouteWithLayout
          exact
          path={`${match.path}/register`}
          // component={LoginPage}
          component={() => <Redirect to={`/v1/${version}/login`} />} // TODO: Replace with LoginPage if will be implemented
        />
        <RouteWithLayout
          exact
          path={`${match.path}/forgot-password`}
          component={LoginPage}
        />
        <RouteWithLayout
          path={`${match.path}/change-password:token`}
          component={LoginPage}
        />
        <PrivateRoute exact path={`${match.path}/chat`} component={ChatPage} />
        <Route
          exact
          path={`${match.path}/proposal/:threadId`}
          component={ProposalPage}
        />
        <Route path={`${match.path}/from-agents`} component={AgentRouter} />
      </Switch>
    </SwarmProvider>
  );
};

export default SwarmRoutes;
