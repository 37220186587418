import styled from 'styled-components';

const FormElement = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px 0px;
  min-width: 500px;
  margin: 0;
  padding: 16px;
  box-zising: border-box;
  transition: all 0.3s ease;

  @media screen and (max-width: 420px) {
    display: flex;
    height: auto;
    padding: 0px;
  }
`;

const FormDesktopRoot = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0px 12px;
`;

const InputAndErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px 0px;
  min-width: 500px;
  margin: 0;
  box-sizing: border-box;
  transition: all 0.3s ease;

  & > p {
    color: red;
  }
`;

const InputRoot = styled.input`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  padding: 16px 32px;
  min-height: 1rem;
  max-height: 14rem;
  border: 1px solid #2240d9;
  background-color: transparent;
  border-radius: 8px;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  color: #003047;
  outline: none;

  &:disabled {
    cursor: not-allowed;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    height: 24px;
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* Estilos para Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
`;

const SendbuttonRoot = styled.button`
  cursor: pointer;
  height: 50px;
  padding: 10px 20px;
  background-color: #2240d9;
  color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px 10px;
  border: none;
  @media screen and (max-width: 420px) {
    padding-top: 6px;
    padding-bottom: 6px;
    box-sizing: border-box;
  }

  &:disabled {
    background-color: #d6e0ef;
    color: #98a2b3;
    cursor: not-allowed;
  }
`;

const FormComponent = ({ inputs = [], onSubmit = () => {}, error = '' }) => {
  return (
    <FormElement onSubmit={onSubmit}>
      <FormDesktopRoot>
        <InputAndErrorContainer>
          {inputs.length > 0 ? (
            inputs.map((input) => <InputRoot {...input} />)
          ) : (
            <></>
          )}
          {error && <p>{error}</p>}
        </InputAndErrorContainer>
        <SendbuttonRoot>Enviar</SendbuttonRoot>
      </FormDesktopRoot>
    </FormElement>
  );
};

export default FormComponent;
