import { useState, useCallback, useRef, useEffect } from 'react';
import { ClientExecutionDomain } from '../../../../modules/clientExecution/domain';

/**
 * @typedef {import('../../../../modules/clientExecution/domain/entities/clientUserExecution.entity').ClientUserAgentExecutionEntity} ClientUserAgentExecutionEntity
 * @typedef {import('../../../../modules/clientExecution/domain/entities/clientUserExecution.entity').PaginationParams} PaginationParams
 */

/**
 * @callback ExecuteCallback
 * @param {PaginationParams} params - Parámetros de búsqueda
 * @returns {Promise<{executions: ClientUserAgentExecutionEntity[], totalPages: number, iterationSkip: number}>}
 */

/**
 * @typedef {Object} GetClientExecutionReturn
 * @property {ExecuteCallback} executeCallback - Función para ejecutar la búsqueda de ejecuciones de cliente
 * @property {Function} loadMore - Función para cargar más ejecuciones
 * @property {ClientUserAgentExecutionEntity[]} executions - Lista de ejecuciones obtenidas
 * @property {number} totalPages - Número total de páginas
 * @property {number} iterationSkip - Última posición de `skip`
 * @property {boolean} hasMore - Indica si hay más páginas disponibles
 * @property {boolean} loading - Estado de carga
 * @property {Error|null} error - Error, si existe
 */

/**
 * Hook para obtener ejecuciones de cliente con paginación
 * @returns {GetClientExecutionReturn}
 */
const useGetClientExecution = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [executions, setExecutions] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [iterationSkip, setIterationSkip] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const entriesPerPage = 100;
  const repositoryRef = useRef(new ClientExecutionDomain());

  /**
   * Filtra ejecuciones válidas basadas en la respuesta
   * @param {ClientUserAgentExecutionEntity[]} executionsToFilter - Ejecuciones a filtrar
   * @returns {ClientUserAgentExecutionEntity[]} - Ejecuciones filtradas
   */
  const filterValidExecutions = useCallback(
    (executionsToFilter) =>
      executionsToFilter.filter(
        (execution) =>
          execution.variables &&
          Object.keys(execution.variables).some((variable) =>
            variable.includes('.response')
          )
      ),
    []
  );

  /**
   * Ejecuta la búsqueda de ejecuciones de cliente
   * @param {PaginationParams} params - Parámetros de búsqueda
   * @returns {Promise<{executions: ClientUserAgentExecutionEntity[], totalPages: number, iterationSkip: number}>}
   */
  const executeCallback = useCallback(
    async (params = { skip: 0, entriesPerPage: 100 }) => {
      setLoading(true);
      setError(null);

      try {
        const { executions: fetchedExecutions, pagination } =
          await repositoryRef.current.getClientUserAgentExecutions({
            ...params,
            entriesPerPage,
          });

        if (!fetchedExecutions || fetchedExecutions.length === 0) {
          setExecutions([]);
          setTotalPages(0);
          setIterationSkip(0);
          setTotalEntries(0);
          setHasMore(false);
          return { executions: [], totalPages: 0, iterationSkip: 0 };
        }

        const validExecutions = filterValidExecutions(fetchedExecutions);

        setExecutions(validExecutions);
        setTotalPages(pagination);
        setIterationSkip(params.skip + entriesPerPage);
        setTotalEntries(pagination * entriesPerPage);
        setHasMore(params.skip + entriesPerPage < pagination * entriesPerPage);

        return {
          executions: validExecutions,
          totalPages: pagination,
          iterationSkip: params.skip + entriesPerPage,
        };
      } catch (err) {
        const errorObj =
          err instanceof Error ? err : new Error('Error desconocido');
        setError(errorObj);
        setHasMore(false);
        throw errorObj;
      } finally {
        setLoading(false);
      }
    },
    [entriesPerPage, filterValidExecutions]
  );

  /**
   * Carga más ejecuciones si hay disponibles
   */
  const loadMore = useCallback(async () => {
    if (!hasMore || loading) return;

    setLoading(true);
    setError(null);

    try {
      const { executions: newExecutions } =
        await repositoryRef.current.getClientUserAgentExecutions({
          skip: iterationSkip,
          entriesPerPage,
        });

      const validExecutions = filterValidExecutions(newExecutions);

      setExecutions((prev) => [...prev, ...validExecutions]);
      setIterationSkip((prevSkip) => prevSkip + entriesPerPage);
      setHasMore(iterationSkip + entriesPerPage < totalEntries);
    } catch (err) {
      const errorObj =
        err instanceof Error ? err : new Error('Error desconocido');
      setError(errorObj);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  }, [
    iterationSkip,
    hasMore,
    loading,
    totalEntries,
    entriesPerPage,
    filterValidExecutions,
  ]);

  useEffect(() => {
    return () => {
      setExecutions([]);
      setTotalPages(0);
      setIterationSkip(0);
      setTotalEntries(0);
      setHasMore(true);
      setLoading(false);
      setError(null);
    };
  }, []);

  return {
    executeCallback,
    loadMore,
    executions,
    totalPages,
    iterationSkip,
    hasMore,
    loading,
    error,
  };
};

export default useGetClientExecution;
