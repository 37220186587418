import styled from 'styled-components';
import TextSkeleton from '../Loader/TextSkeleton';
import SVGComponent from '../SVGComponent';

const FormWrapper = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 250px;
`;

const InputField = styled.input`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
`;

const SendButtonWrapper = styled.button`
  width: 20px;
  height: 20px;
  padding: 4px;
  border-radius: 4px;
  background: #2240d9;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  outline: none;
  border: none;
`;

const FormAskAIComponent = ({
  placeholder,
  name,
  handleFormSubmit,
  isLoading,
}) => {
  return (
    <>
      {isLoading ? (
        <TextSkeleton />
      ) : (
        <FormWrapper onSubmit={handleFormSubmit}>
          <InputField
            type='text'
            placeholder={placeholder}
            name='prompt'
            id={name}
          />
          <SendButtonWrapper type='submit'>
            <SVGComponent src={'/v1/swarm/send-icon.svg'} />
          </SendButtonWrapper>
        </FormWrapper>
      )}
    </>
  );
};

export default FormAskAIComponent;
