import { ApiService } from '../../../../core/api/apiService';
import AGENTS_ENDPOINTS from '../endpoints/agents.endpoints';

/**
 * @typedef {import("../../domain/entities/angets.entity").AgentEntity} AgentEntity
 */

export class GetAgentsRepository extends ApiService {
  constructor(failureHandler) {
    super(failureHandler);
    this.failureHandler = failureHandler;
  }

  /**
   * @inheritdoc
   * @param {Object} params - The parameters to get agents
   * @param {string} [params.name] - The name of the agent to search for
   * @param {number} [params.skip] - The number of agents to skip
   * @param {number} [params.entriesPerPage] - The number of agents to return per page
   * @returns {Promise<AgentEntity[]>} - The result of the search
   * * @throws {Error} - If an error occurs while getting agents
   */
  async getAgents(params) {
    return await this.get(AGENTS_ENDPOINTS.getAgents, params);
  }
}
