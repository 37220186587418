import { IFailureHandler } from '../../../core/interface/IFailureHandler';
import { UsersPorts } from '../domain/ports/users.ports';
import { LoginUserRepository } from './repositories/userRepository';

/**
 * @implements {UsersPorts}
 */
export class UserRepository extends UsersPorts {
  constructor() {
    super();
    this.failureHandler = new IFailureHandler();
    this.loginUserRepository = new LoginUserRepository(this.failureHandler);
  }

  /**
   * @inheritdoc
   */
  async authenticate(data) {
    return await this.loginUserRepository.authenticate(data);
  }
}
