import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAgentExecution } from '../hooks/use-agent-execution.hook';
import { useGetAgentVersions } from '../hooks/get-agents.hook';
import '../styles/ExecutePage.css';
import DebounceSelector from '../../shared/DebounceSelector/DebounceSelector';
import AutoResizeTextArea from '../components/shared/InputTextArea';
import StepLoader from '../../components/Loader/StepLoader';
import Modal from '../../shared/Modal/ModalComponent';
import AgentsManagerLoginForm from '../components/LoginForm';
import { useExecuteAgentContext } from '../../contexts/ExecuteAgents.context';
import useGetUserClients from '../hooks/use-get-user-clients.hook';
import ClientSelector from '../../shared/ClientSelector';
import useGetClientExecution from '../hooks/use-get-client-executions.hook';

const ExecutePage = () => {
  const { userToken, updateClient, client } = useExecuteAgentContext();
  const { executeCallback: fetchClientExecutions } = useGetClientExecution();
  const [userInput, setUserInput] = useState('');
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const history = useHistory();

  const { executeCallback: getAgentList } = useGetAgentVersions();

  const { executeAgent, loading, error } = useAgentExecution();

  const { executeCallback: getUserClients } = useGetUserClients();

  const handleSearchClients = async (search = '') => {
    try {
      const results = await getUserClients(search);
      return results.map((client) => ({
        id: client.id,
        name: client.name,
      }));
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const handleClientSelect = (value, client) => {
    updateClient(client);
  };

  const fetchAgentsByClient = async (search = '') => {
    try {
      const agents = await getAgentList({
        search: search,
        skip: 0,
        entriesPerPage: 10,
      });
      return agents;
    } catch (error) {
      console.error('Error fetching agents:', error);
    }
  };

  const handleAgentSelect = (value, agent) => {
    setSelectedAgent(agent);
    const defaultFlow = agent.flows ? agent.flows[0] : null;
    setSelectedVersion(defaultFlow.version);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const executionDTO = {
        user_input: userInput,
        agent_id: selectedAgent.id,
        version: selectedVersion,
        app_client_user_id: client.id,
      };
      const responseFromGraphEngine = await executeAgent(executionDTO);
      if (
        responseFromGraphEngine &&
        responseFromGraphEngine.langsmith_trace_id
      ) {
        await fetchClientExecutions();
        history.push(
          `/v1/autosales/from-agents/execution/${responseFromGraphEngine.langsmith_trace_id}`
        );
      }
    } catch (error) {
      console.error('Error al ejecutar el agente:', error);
    }
  };

  return (
    <>
      <Modal closable={false} isModalOpen={!userToken}>
        <AgentsManagerLoginForm />
      </Modal>
      <div className="execute-container">
        {loading ? (
          <StepLoader />
        ) : (
          <div className="execute-content">
            <form onSubmit={handleSubmit} className="execute-form">
              {error && (
                <div className="error-message">
                  <p>{error.message}</p>
                </div>
              )}
              <div className="selectors-row">
                <div className="selector-container-in-row">
                  <div className="selector-container">
                    <label htmlFor="agent_id">Select Agent</label>
                    <DebounceSelector
                      name="agent_id"
                      placeholder="Search Agent..."
                      onSearch={fetchAgentsByClient}
                      onSelect={(value, suggestion) => {
                        handleAgentSelect(value, suggestion);
                      }}
                    />
                  </div>
                </div>

                <div className="selector-container">
                  <label htmlFor="version-selector">Agent Version</label>
                  <select
                    disabled={
                      !selectedAgent ||
                      !selectedAgent.flows ||
                      selectedAgent.flows.length === 0
                    }
                    id="agent_version"
                    defaultValuevalue={selectedVersion}
                    onChange={(e) => {
                      setSelectedVersion(e.target.value);
                      const selectedFlow = selectedAgent.flows
                        .sort((a, b) => a - b)
                        .find((flow) => flow.id === e.target.value);
                      if (selectedFlow) {
                        setSelectedVersion(selectedFlow.version);
                      }
                    }}
                    className="version-select"
                  >
                    {selectedAgent &&
                      selectedAgent.flows &&
                      selectedAgent.flows.map((flow) => (
                        <option key={flow.id} value={flow.id}>
                          {flow.version}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="input-row">
                <AutoResizeTextArea
                  id="user_input"
                  name="user_input"
                  defaultValue={userInput}
                  onBlur={(e) => setUserInput(e.target.value)}
                />
                <button
                  type="submit"
                  className="send-button"
                  disabled={
                    loading ||
                    !userInput ||
                    !selectedAgent?.id ||
                    !selectedVersion
                  }
                >
                  {loading ? 'Processing...' : 'Send'}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default ExecutePage;
