// src/modules/clientUserAgentExecutions/data/repositories/getClientUserAgentExecution.repository.ts

import { ApiService } from '../../../../core/api/apiService';
import CLIENT_USER_AGENT_EXECUTIONS_ENDPOINTS from '../endpoints/clientUserExecutions.endpoints';

/**
 * @typedef {import('../../domain/entities/clientUserExecution.entity').ClientUserAgentExecutionResponseDTO} ClientUserAgentExecutionResponseDTO
 */


/**
 * @typedef {import('../../domain/entities/clientUserExecution.entity').PaginationParams} PaginationParams
 */

export class GetClientUserAgentExecutionsRepository extends ApiService {
  constructor(failureHandler) {
    super(failureHandler);
    this.failureHandler = failureHandler;
  }

  /**
   * @description Get client user agent executions
   * @param {PaginationParams} paginationParams - Parameters for the function
   * @returns {Promise<ClientUserAgentExecutionResponseDTO>}
   */
  async getExecutions(paginationParams) {
    const responseData = await this.get(
      CLIENT_USER_AGENT_EXECUTIONS_ENDPOINTS.getWithPagination,
      paginationParams
    );
    return responseData;
  }
}
