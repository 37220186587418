import React, { useState } from 'react';
import useAgentManagerLogin from '../../hooks/use-agent-manager-login.hook';
import {
  FormContainer,
  FormTitle,
  FormElement,
  FormGroup,
  Label,
  Input,
  ErrorMessage,
  SubmitButton,
  FormError,
  SuccessMessage,
} from './LoginForm.styles';
import { useExecuteAgentContext } from '../../../contexts/ExecuteAgents.context';

const LoginFormComponent = () => {
  const { updateUserToken } = useExecuteAgentContext();
  const { execute, isLoading, error } = useAgentManagerLogin();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validationErrors, setValidationErrors] = useState({
    email: '',
    password: '',
  });
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const validateForm = () => {
    const errors = {
      email: '',
      password: '',
    };
    let isValid = true;

    if (!email) {
      errors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Enter a valid email address';
      isValid = false;
    }

    if (!password) {
      errors.password = 'Password is required';
      isValid = false;
    }

    setValidationErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitSuccess(false);

    if (!validateForm()) {
      return;
    }

    try {
      const userLogged = await execute({ email, password });

      if (userLogged.error) {
        setValidationErrors({
          email: userLogged.message,
          password: userLogged.message,
        });
        return;
      }
      if (userLogged) {
        updateUserToken(userLogged.accessToken);
      }
      setSubmitSuccess(true);
    } catch (err) {
      console.error('Error al iniciar sesión:', err);
    }
  };

  return (
    <FormContainer>
      <FormTitle>Check your Agent Manager Account</FormTitle>

      {error && (
        <FormError>
          {error.message || 'Something went wrong. Please try again.'}
        </FormError>
      )}

      {submitSuccess && (
        <SuccessMessage>
          Login successful! You are now authenticated.
        </SuccessMessage>
      )}

      <FormElement onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="email">Email:</Label>
          <Input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your agent manager account email."
            hasError={!!validationErrors.email}
            disabled={isLoading}
          />
          {validationErrors.email && (
            <ErrorMessage>{validationErrors.email}</ErrorMessage>
          )}
        </FormGroup>

        <FormGroup>
          <Label htmlFor="password">Password:</Label>
          <Input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your agent manager account password."
            hasError={!!validationErrors.password}
            disabled={isLoading}
          />
          {validationErrors.password && (
            <ErrorMessage>{validationErrors.password}</ErrorMessage>
          )}
        </FormGroup>

        <SubmitButton type="submit" disabled={isLoading}>
          {isLoading ? 'Logging in...' : 'Login'}
        </SubmitButton>
      </FormElement>
    </FormContainer>
  );
};

export default LoginFormComponent;
