/**
 * @typedef {import('../entities/clientUserExecution.entity').ClientUserAgentExecutionResponseDTO} ClientUserAgentExecutionResponseDTO
 */


/**
 * @typedef {import('../entities/clientUserExecution.entity').PaginationParams} PaginationParams
 */

export class ClientUserExecutionPort {
  /**
   * @description Get client user agent executions
   * @param {PaginationParams} params - Parameters for the function
   * @returns {Promise<ClientUserAgentExecutionResponseDTO>}
   */
  async getClientUserAgentExecutions(params) {
    throw new Error('Method not implemented.');
  }
}
