import styled from 'styled-components';

export const ClientSelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 0px;
  box-sizing: border-box;
  width: 100%;
  height: auto;
`;
