import { useState, useEffect } from 'react';

/**
 * Custom hook for debouncing a value
 * @param {Object} params - Hook parameters
 * @param {any} params.value - The value to debounce
 * @param {number} params.delay - Delay in milliseconds
 * @returns {any} The debounced value
 */
const useDebounce = ({ value, delay }) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;