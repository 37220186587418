import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Radio,
  FormControlLabel,
  Checkbox,
  Button,
  RadioGroup,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import useGetLocationIp from '../../hooks/LocationIp';

const Link = styled.div`
  position: relative;
  line-height: 150%;
`;
const ShortHeadingHere = styled.h1`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-family: Roboto;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  @media screen and (max-width: 520px) {
    font-size: 40px;
    max-width: 90%;
  }
`;
const LoremIpsumDolor = styled.div`
  align-self: stretch;
  position: relative;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;
const Content = styled.div`
  display: flex;
  width: 58%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  flex: 1 0 0;
  @media screen and (max-width: 1024px) {
    width: 80%;
  }
  @media screen and (max-width: 768px) {
    width: 80%;
  }
  @media screen and (max-width: 520px) {
    width: 100%;
    gap: 16px;
    align-self: stretch;
  }
`;
const Header = styled.div`
  width: 100%;
  background-color: #0e1d39;
  height: auto;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 112px 64px;
  box-sizing: border-box;
  font-size: 56px;
  color: #fff;
  @media screen and (max-width: 520px) {
    padding: 112px 24px 32px 24px;
    box-sizing: border-box;
  }
`;
const Subheading = styled.div`
  position: relative;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`;
const Heading = styled.h1`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  @media screen and (max-width: 520px) {
    font-size: 36px;
  }
`;
const Text = styled.div`
  align-self: stretch;
  position: relative;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;
const Content1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 24px;
  justify-content: flex-start;
  text-align: left;
  font-size: 48px;
`;
const SectionTitle = styled.div`
  display: flex;
  width: 58%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  @media screen and (max-width: 1024px) {
    width: 80%;
  }
  @media screen and (max-width: 768px) {
    width: 80%;
  }
  @media screen and (max-width: 520px) {
    width: 100%;
  }
`;
const FirstName = styled.div`
  align-self: stretch;
  position: relative;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;
const FirstNameTitle = styled.div`
  align-self: stretch;
  position: relative;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-top: 24px;
`;
const RadioParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
`;
const Radio1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`;
const PhoneNumber = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
`;
const ErrorMessage = styled.div`
  color: #ef0000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;
const Input2 = styled.input`
  padding: 10.5px;
  align-self: stretch;
  border: 1px solid #000;
  cursor: pointer;
  color: #0e1d39;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  &:focus {
    border-color: #000;
    outline: none;
  }
`;
const Select = styled.select`
  width: 100%;
  padding: 12px;
  align-self: stretch;
  border: 1px solid #000;
  cursor: pointer;
  color: #0e1d39;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  &:focus {
    border-color: #000;
    outline: none;
  }
`;
const Option = styled.option`
  padding: 12px;
  flex: 1 0 0;
  color: #0e1d39;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;
const Input = styled.div`
  flex: 1;
  display: flex;
  min-width: 345px;
  max-width: 372px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  @media screen and (max-width: 1200px) {
    min-width: 40%;
  }
  @media screen and (max-width: 900px) {
    min-width: 30%;
  }
  @media screen and (max-width: 768px) {
    min-width: 100%;
  }
`;
const Input1 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  min-width: 345px;
  max-width: 372px;
  @media screen and (max-width: 1200px) {
    min-width: 40%;
  }
  @media screen and (max-width: 900px) {
    min-width: 30%;
  }
  @media screen and (max-width: 768px) {
    min-width: 100%;
  }
`;
const InputByForm = styled.div`
  flex: 1;
  display: flex;
  min-width: 372px;
  max-width: 372px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  @media screen and (max-width: 768px) {
    min-width: 100%;
  }
`;
const InputByForm2 = styled.div`
  display: flex;
  min-width: 372px;
  max-width: 372px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 160px;
  @media screen and (max-width: 768px) {
    min-width: 100%;
    flex-direction: column;
    gap: 48px;
  }
`;
const Input1ByForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  min-width: 372px;
  max-width: 372px;
  @media screen and (max-width: 768px) {
    min-width: 100%;
  }
`;
const Inputs = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  color: #fff;
  @media screen and (max-width: 768px) {
    gap: 32px;
    max-width: 100%;
    flex-direction: column;
  }
`;
const InputsResults = styled.div`
  // align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 48px;
  color: #fff;
  @media screen and (max-width: 768px) {
    gap: 32px;
    max-width: 100%;
  }
`;
const Inputs1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const Inputs2 = styled.div`
  align-self: stretch;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  @media screen and (max-width: 520px) {
    gap: 32px;
  }
`;
const FormControl = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
`;
const Column4 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 14px;
`;
const Content2 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
`;
const Radios = styled.div`
  max-width: 793px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 0px;
  gap: 16px;
`;
const StyleprimarySmallfalseDa = withStyles({
  root: {
    display: 'flex',
    width: 'auto',
    padding: '12px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    border: '1px solid #F5F5F5',
    borderRadius: 0,
    backgroundColor: '#FFA800',
    color: '#0E1D39',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '150%',
    '&:hover': {
      backgroundColor: '#CC7A00',
    },
    '&:disabled': {
      backgroundColor: '#283F6B',
      color: '#F5F5F5',
    },
  },
})(Button);
const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  text-align: left;
  color: #f5f5f5;
`;
const Contact = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  padding: 112px 64px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 48px;
  align-self: stretch;
  box-sizing: border-box;
  text-align: center;
  color: #fff;
  @media screen and (max-width: 520px) {
    padding: 32px 24px;
  }
`;
const Text1 = styled.div`
  align-self: stretch;
  position: relative;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;
const Content8 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
`;
const StyleprimarySmallfalseDa2 = withStyles({
  root: {
    display: 'flex',
    padding: '12px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    border: '1px solid #F5F5F5',
    borderRadius: 0,
    backgroundColor: '#F5F5F5',
    color: '#0E1D39',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '150%',
    '&:hover': {
      backgroundColor: '#C9C9C9',
    },
  },
})(Button);
const StyleprimarySmallfalseDa3 = withStyles({
  root: {
    display: 'flex',
    padding: '12px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    border: '1px solid #FFF',
    borderRadius: 0,
    backgroundColor: 'transparent',
    color: '#FFF',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '150%',
    '&:hover': {
      backgroundColor: '#EDEDED80',
    },
  },
})(Button);
const Actions3 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 0px 0px;
  gap: 16px;
`;
const Column7 = styled.div`
  width: 58%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  @media screen and (max-width: 1024px) {
    width: 80%;
  }
  @media screen and (max-width: 768px) {
    width: 80%;
  }
  @media screen and (max-width: 520px) {
    width: 100%;
  }
`;
const Cta1 = styled.div`
  width: 100%;
  background-color: #00aa58;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 112px 64px;
  box-sizing: border-box;
  font-size: 48px;
  color: #fff;
  @media screen and (max-width: 520px) {
    width: 100%;
    padding: 32px 24px;
    box-sizing: border-box;
  }
`;
const Faqs = styled.h1`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 120%;
  font-weight: 700;
  font-family: inherit;
`;
const Question = styled.b`
  flex: 1;
  position: relative;
  line-height: 150%;
`;
const PlusIcon = styled.img`
  width: 32px;
  position: relative;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
`;
const AnswerContent = styled.div`
  align-self: stretch;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;
const CatalogAIText = styled.span`
  color: #f5f5f5;
  font-weight: 700;
`;
const HereText = styled.span`
  color: #ffa800;
  font-weight: 700;
  cursor: pointer;
`;
const QuestionContent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const AccordionItem = styled.div`
  align-self: stretch;
  border: 1px solid #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 24px;
  gap: 8px;
`;
const Accordion = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  font-size: 18px;
`;
const StillHaveA = styled.h1`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 130%;
  font-weight: 700;
  font-family: inherit;
`;
const Content9 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  @media screen and (max-width: 520px) {
    width: 100%;
  }
`;
const Content10 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  font-size: 32px;
  @media screen and (max-width: 520px) {
    width: 100%;
  }
`;
const Faq = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 112px 64px;
  box-sizing: border-box;
  gap: 80px;
  font-size: 48px;
  @media screen and (max-width: 520px) {
    gap: 40px;
    padding: 32px 24px;
    box-sizing: border-box;
  }
`;
const DemoDesktopRoot = styled.div`
  position: relative;
  background-color: #0e1d39;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: 16px;
  color: #f5f5f5;
  font-family: Roboto;
  @media screen and (max-width: 420px) {
    padding-left: 0px;
    box-sizing: border-box;
  }
`;

// Estilos personalizados para el Radio
const CustomRadio = withStyles({
  root: {
    color: '#FFFFFF', // color cuando no está seleccionado
    '&$checked': {
      color: '#FFFFFF', // color cuando está seleccionado
    },
  },
  checked: {},
})((props) => (
  <Radio
    icon={<CircleIcon />}
    checkedIcon={<CheckCircleOutlineIcon />}
    {...props}
  />
));

const CatalogAIDemo = () => {
  const { t } = useTranslation(['catalogAI-demo']);

  const [formData, setFormData] = useState({
    year: '',
    brand: '',
    subBrand: '',
    descripcion: '',
    selectedInsurances: [],
  });

  const [vehicleData, setVehicleData] = useState({
    vehicleKey: '',
    year: '',
    insuranceCompany: '',
    selectedInsurances: [],
  });

  const [data, setData] = useState({
    brand: null,
    subBrand: null,
    descripcion: null,
    insuranceCompany: null,
    formDataResult: null,
    vehicleDataResult: null,
  });

  const [isAnswerVisible, setIsAnswerVisible] = useState({
    q1: false,
    q2: false,
    q3: false,
    q4: false,
    q5: false,
    q6: false,
    q7: false,
  });

  const [isFormVisible, setIsFormVisible] = useState({
    byForm: true,
    vehicleKey: false,
  });

  const [validationErrors, setValidationErrors] = useState({
    year: false,
    brand: false,
    subBrand: false,
    descripcion: false,
    selectedInsurances: false,
  });
  const [validationErrorsVehicle, setValidationErrorsVehicle] = useState({
    vehicleKey: false,
    year: false,
    insuranceCompany: false,
    selectedInsurances: false,
  });

  // ENDPOINT GET
  const fetchData = async ({ url, params, section, method }) => {
    const username = 'therocketteam@apitherocketcode.com';
    const password = 'h3uStQK6q7wf1Aek';
    const headers = new Headers();
    headers.append('Authorization', 'Basic ' + btoa(username + ':' + password));
    headers.append('Accept', 'application/json');

    // Función para convertir el objeto de parámetros en una cadena de consulta
    let queryParams = params ? new URLSearchParams(params).toString() : '';
    // queryParams = queryParams.replace(/\+/g, "%");
    const fullUrl = queryParams ? `${url}?${queryParams}` : url;

    try {
      const baseUrl = process.env.REACT_APP_API_URL_CATALOGOS_DEMO;
      const response = await fetch(`${baseUrl}${fullUrl}`, {
        method: method,
        headers: headers,
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      setData((prevData) => ({ ...prevData, [section]: data }));
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
    }
  };

  const handleFetchData = ({ link, param, sections, response, methods }) => {
    fetchData({ url: link, params: param, section: sections, method: methods });
  };

  //Get Brands
  useEffect(() => {
    if (
      formData.year >= new Date().getFullYear() - 20 &&
      formData.year <= new Date().getFullYear()
    ) {
      handleFetchData({
        link: 'catalogs/qualitas/brands-by-model',
        sections: 'brand',
        methods: 'GET',
        param: {
          model: formData.year,
        },
      });
    }
  }, [formData.year]);

  //Get Sub-Brands
  useEffect(() => {
    if (
      formData.year >= new Date().getFullYear() - 20 &&
      formData.year <= new Date().getFullYear() &&
      formData.brand !== null
    ) {
      handleFetchData({
        link: 'catalogs/qualitas/subbrands',
        sections: 'subBrand',
        methods: 'GET',
        param: {
          model: formData.year,
          brand: formData.brand,
        },
      });
    }
  }, [formData.year, formData.brand]);

  //Get Description
  useEffect(() => {
    if (
      formData.year >= new Date().getFullYear() - 20 &&
      formData.year <= new Date().getFullYear() &&
      formData.brand !== null &&
      formData.subBrand !== null
    ) {
      handleFetchData({
        link: 'catalogs/qualitas/description',
        sections: 'descripcion',
        methods: 'GET',
        param: {
          model: formData.year,
          brand: formData.brand,
          subBrand: formData.subBrand,
        },
      });
    }
  }, [formData.year, formData.brand, formData.subBrand]);

  const [tiempoRespuesta, setTiempoRespuesta] = useState('0 seg');

  const fetchDataPOST = async ({ url, params, section, method }) => {
    const username = 'therocketteam@apitherocketcode.com';
    const password = 'h3uStQK6q7wf1Aek';
    const headers = new Headers();
    headers.append('Authorization', 'Basic ' + btoa(username + ':' + password));
    headers.append('Content-Type', 'application/json'); // Asegúrate de incluir este tipo de contenido

    const startTime = performance.now();

    try {
      const baseUrl = process.env.REACT_APP_API_URL_CATALOGOS_DEMO;
      const response = await fetch(`${baseUrl}${url}`, {
        method: method,
        headers: headers,
        body: JSON.stringify(params),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error response data:', errorData);
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      setData((prevData) => ({ ...prevData, [section]: data }));

      const endTime = performance.now();
      const time = ((endTime - startTime) / 1000).toFixed(2);
      setTiempoRespuesta(`${time} seg`);
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
      const endTime = performance.now();
      const time = ((endTime - startTime) / 1000).toFixed(2);
      setTiempoRespuesta(`${time} seg`);
    }
  };

  const handleFetchDataPOST = ({
    link,
    param,
    sections,
    response,
    methods,
  }) => {
    fetchDataPOST({
      url: link,
      params: param,
      section: sections,
      method: methods,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    const { checked, name } = event.target;
    let insurancesArray =
      typeof formData.selectedInsurances === 'string'
        ? formData.selectedInsurances.split(', ').filter(Boolean)
        : formData.selectedInsurances;
    const nameLowerCase = name.toLowerCase();
    if (checked) {
      if (!insurancesArray.includes(nameLowerCase)) {
        insurancesArray.push(nameLowerCase);
      }
    } else {
      insurancesArray = insurancesArray.filter(
        (insurance) => insurance !== nameLowerCase
      );
    }
    setFormData({
      ...formData,
      selectedInsurances: insurancesArray,
    });
  };

  const handleCheckboxChangeVehicle = (event) => {
    const { checked, name } = event.target;
    let insurancesArray =
      typeof vehicleData.selectedInsurances === 'string'
        ? vehicleData.selectedInsurances.split(', ').filter(Boolean)
        : vehicleData.selectedInsurances;
    const nameLowerCase = name.toLowerCase();
    if (checked) {
      if (!insurancesArray.includes(nameLowerCase)) {
        insurancesArray.push(nameLowerCase);
      }
    } else {
      insurancesArray = insurancesArray.filter(
        (insurance) => insurance !== nameLowerCase
      );
    }
    setVehicleData({
      ...vehicleData,
      selectedInsurances: insurancesArray,
    });
  };

  const handleChangeVehicle = (e) => {
    const { name, value } = e.target;
    setVehicleData({ ...vehicleData, [name]: value });
  };

  const toggleAnswerVisibility = (question) => {
    setIsAnswerVisible({
      ...isAnswerVisible,
      [question]: !isAnswerVisible[question],
    });
  };

  const toggleFormVisibility = (radio) => {
    if (radio === 'byForm') {
      setIsFormVisible({ byForm: true, vehicleKey: false });
    }
    if (radio === 'vehicleKey') {
      setIsFormVisible({ byForm: false, vehicleKey: true });
    }
  };

  const validateForm = () => {
    let errors = {};
    if (
      formData.year < new Date().getFullYear() - 20 ||
      formData.year > new Date().getFullYear()
    ) {
      errors = { ...errors, year: true };
    }
    if (!formData.brand) {
      errors = { ...errors, brand: true };
    }
    if (!formData.subBrand) {
      errors = { ...errors, subBrand: true };
    }
    if (!formData.descripcion) {
      errors = { ...errors, descripcion: true };
    }
    if (
      !formData.selectedInsurances ||
      formData.selectedInsurances.length === 0
    ) {
      errors = { ...errors, selectedInsurances: true };
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const validateFormVehicle = () => {
    let errors = {};
    if (!vehicleData.vehicleKey) {
      errors = { ...errors, vehicleKey: true };
    }
    if (
      vehicleData.year < new Date().getFullYear() - 20 ||
      vehicleData.year > new Date().getFullYear()
    ) {
      errors = { ...errors, year: true };
    }
    if (!vehicleData.insuranceCompany) {
      errors = { ...errors, insuranceCompany: true };
    }
    if (
      !vehicleData.selectedInsurances ||
      vehicleData.selectedInsurances.length === 0
    ) {
      errors = { ...errors, selectedInsurances: true };
    }
    setValidationErrorsVehicle(errors);
    return Object.keys(errors).length === 0;
  };
  const areRequiredFieldsFilled = (dataObject, requiredFields) => {
    return requiredFields.every((field) => dataObject[field]);
  };

  function getUTMSource() {
    const urlObj = new URL(window.location.href);
    const params = new URLSearchParams(urlObj.search);
    return {
      utm_source: params.get('utm_source'),
      utm_medium: params.get('utm_medium'),
      utm_campaign: params.get('utm_campaign'),
    };
  }

  useEffect(() => {
    let errors = {};
    if (isFormVisible.byForm) {
      if (
        (formData.year && formData.year < new Date().getFullYear() - 20) ||
        formData.year > new Date().getFullYear()
      ) {
        errors = { ...errors, year: true };
      }
      setValidationErrors(errors);
    } else {
      if (
        (vehicleData.year &&
          vehicleData.year < new Date().getFullYear() - 20) ||
        vehicleData.year > new Date().getFullYear()
      ) {
        errors = { ...errors, year: true };
      }
      setValidationErrorsVehicle(errors);
    }
  }, [formData.year, vehicleData.year, isFormVisible.byForm]);

  const [, , , ip] = useGetLocationIp();
  const handleSubmit = () => {
    const utmParams = getUTMSource();
    if (isFormVisible.byForm) {
      if (validateForm()) {
        handleFetchDataPOST({
          link: 'catalogs/vehicles-by-brand',
          sections: 'formDataResult',
          methods: 'POST',
          param: {
            brand: formData.brand,
            subbrand: formData.subBrand,
            model: parseInt(formData.year, 10),
            description: formData.descripcion,
            insurer: formData.selectedInsurances,
            ip: ip,
            userId: utmParams.utm_campaign,
            utmSource: utmParams.utm_source,
            utmMedium: utmParams.utm_medium,
          },
        });
      }
    } else {
      if (validateFormVehicle()) {
        handleFetchDataPOST({
          link: 'catalogs/vehicles-by-amis',
          sections: 'vehicleDataResult',
          methods: 'POST',
          param: {
            model: parseInt(vehicleData.year, 10),
            amisInsurer: vehicleData.insuranceCompany,
            amis: vehicleData.vehicleKey,
            insurer: vehicleData.selectedInsurances,
            ip: ip,
            userId: utmParams.utm_campaign,
            utmSource: utmParams.utm_source,
            utmMedium: utmParams.utm_medium,
          },
        });
      }
    }
  };

  const [efectividad, setEfectividad] = useState('0%');
  const [coincidencias, setCoincidencias] = useState('0/0');

  useEffect(() => {
    if (data.formDataResult?.results) {
      const totalEntries = Object.keys(data.formDataResult.results).length;
      const amisValidos = Object.values(data.formDataResult.results).filter(
        (result) => result.AMIS
      ).length;
      const porcentajeEfectividad = (
        (amisValidos / totalEntries) *
        100
      ).toFixed(0);
      const coincidenciasEncontradas = `${amisValidos}/${totalEntries}`;

      setEfectividad(`${porcentajeEfectividad}%`);
      setCoincidencias(coincidenciasEncontradas);
    }
  }, [data.formDataResult]);
  useEffect(() => {
    if (data.vehicleDataResult?.results) {
      const totalEntries = Object.keys(data.vehicleDataResult.results).length;
      const amisValidos = Object.values(data.vehicleDataResult.results).filter(
        (result) => result.AMIS
      ).length;
      const porcentajeEfectividad = (
        (amisValidos / totalEntries) *
        100
      ).toFixed(0);
      const coincidenciasEncontradas = `${amisValidos}/${totalEntries}`;

      setEfectividad(`${porcentajeEfectividad}%`);
      setCoincidencias(coincidenciasEncontradas);
    }
  }, [data.vehicleDataResult]);

  const insuranceItemsForm = formData.selectedInsurances
    .map((insurance) => {
      let formattedInsurance = insurance.toUpperCase(); // Convierte todo a mayúsculas
      if (formattedInsurance === 'QUALITAS') {
        formattedInsurance = 'QUÁLITAS'; // Agrega el acento
      }
      return formattedInsurance;
    })
    .join(', ');

  const insuranceItemsVehicle = vehicleData.selectedInsurances
    .map((insurance) => {
      let formattedInsurance = insurance.toUpperCase(); // Convierte todo a mayúsculas
      if (formattedInsurance === 'QUALITAS') {
        formattedInsurance = 'QUÁLITAS'; // Agrega el acento
      }
      return formattedInsurance;
    })
    .join(', ');

  const handleOnClickForm = () => {
    if (isFormVisible.byForm) {
      if (validateForm()) {
        setData((prevData) => ({
          ...prevData,
          brand: null,
          subBrand: null,
          descripcion: null,
          insuranceCompany: null,
          formDataResult: null,
          vehicleDataResult: null,
        }));
        setFormData((prevData) => ({
          ...prevData,
          year: '',
          brand: '',
          subBrand: '',
          descripcion: '',
          selectedInsurances: [],
        }));
        setIsFormVisible({ byForm: true, vehicleKey: false });
        setEfectividad('0%');
        setCoincidencias('0/0');
        setTiempoRespuesta('0 seg');
      }
    } else {
      if (validateFormVehicle()) {
        setData((prevData) => ({
          ...prevData,
          brand: null,
          subBrand: null,
          descripcion: null,
          insuranceCompany: null,
          formDataResult: null,
          vehicleDataResult: null,
        }));
        setVehicleData((prevData) => ({
          ...prevData,
          vehicleKey: '',
          year: '',
          insuranceCompany: '',
          selectedInsurances: [],
        }));
        setIsFormVisible({ byForm: false, vehicleKey: true });
        setEfectividad('0%');
        setCoincidencias('0/0');
        setTiempoRespuesta('0 seg');
      }
    }
  };

  const goToContact = () => {
    window.location.href = '/v1/contact-ai';
  };

  const goToCatalogo = () => {
    window.location.href = '/v1/catalogos';
  };

  return (
    <DemoDesktopRoot>
      <Header>
        <Content>
          <ShortHeadingHere>{`${t('header.title')}`}</ShortHeadingHere>
          <LoremIpsumDolor>{`${t('header.subtitle')}`}</LoremIpsumDolor>
        </Content>
      </Header>
      <Contact>
        <SectionTitle>
          <Subheading>{`${t('section.title1')}`}</Subheading>
          <Content1>
            <Heading>{`${t('section.title2')}`}</Heading>
            <Text>{`${t('section.subtitle')}`} </Text>
          </Content1>
        </SectionTitle>
        <Form>
          {!data?.formDataResult && !data?.vehicleDataResult && (
            <Radio1>
              <FirstName>{`${t('form.title')}`}</FirstName>
              <RadioParent>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  // defaultValue="byForm"
                  value={isFormVisible.byForm ? 'byForm' : 'vehicleKey'}
                  name="radio-buttons-group"
                  row
                >
                  <FormControlLabel
                    value="byForm"
                    label={`${t('form.option1')}`}
                    control={<CustomRadio />}
                    onClick={() => toggleFormVisibility('byForm')}
                  />
                  <FormControlLabel
                    value="vehicleKey"
                    label={`${t('form.option2')}`}
                    control={<CustomRadio />}
                    onClick={() => toggleFormVisibility('vehicleKey')}
                  />
                </RadioGroup>
              </RadioParent>
            </Radio1>
          )}
          {data.formDataResult && (
            <Radio1>
              <FirstNameTitle>{`${t(
                'formResults.typeSearch'
              )}`}</FirstNameTitle>
              <Subheading>{`${t('formResults.typeSearch1')}`}</Subheading>
              <FirstNameTitle>{`${t('formResults.vehicle')}`}</FirstNameTitle>
              <Subheading>{`${formData.year} ${formData.brand} ${formData.subBrand}`}</Subheading>
              <FirstNameTitle>{`${t(
                'formResults.insuranceCompanies'
              )}`}</FirstNameTitle>
              <Subheading>{insuranceItemsForm}</Subheading>
            </Radio1>
          )}
          {data.vehicleDataResult && (
            <Radio1>
              <FirstNameTitle>{`${t(
                'formVehicleResults.typeSearch'
              )}`}</FirstNameTitle>
              <Subheading>{`${t(
                'formVehicleResults.typeSearch1'
              )}`}</Subheading>
              <FirstNameTitle>{`${t(
                'formVehicleResults.clave'
              )}`}</FirstNameTitle>
              <Subheading>{`${vehicleData.vehicleKey}`}</Subheading>
              <FirstNameTitle>{`${t(
                'formVehicleResults.insuranceCompanies'
              )}`}</FirstNameTitle>
              <Subheading>{insuranceItemsVehicle}</Subheading>
            </Radio1>
          )}
          {isFormVisible.byForm && !data?.formDataResult && (
            <FormControl>
              <Inputs2>
                <Inputs>
                  <InputByForm>
                    <PhoneNumber>{`${t('form.input1')}`}</PhoneNumber>
                    <Input2
                      id="year"
                      name="year"
                      placeholder={`${t('form.inputPlaceholder')}`}
                      onChange={handleChange}
                      value={formData.year}
                    />
                    {validationErrors.year && (
                      <ErrorMessage>
                        {`${t('errors.yearValid')}`} ({new Date().getFullYear()}{' '}
                        - {new Date().getFullYear() - 20}).
                      </ErrorMessage>
                    )}
                  </InputByForm>
                  <Input1ByForm>
                    <PhoneNumber>{`${t('form.select2')}`}</PhoneNumber>
                    <Select
                      id="brand"
                      name="brand"
                      onChange={handleChange}
                      value={formData.brand}
                    >
                      <Option value="">{`${t('form.select2')}`}</Option>
                      {Array.isArray(data.brand) && data.brand.length > 0 ? (
                        data.brand.map((item, index) => (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        ))
                      ) : (
                        <Option disabled>{`${t('form.optionBrand')}`}</Option>
                      )}
                    </Select>
                    {validationErrors.brand && (
                      <ErrorMessage>{`${t(
                        'errors.fileRequired'
                      )}`}</ErrorMessage>
                    )}
                  </Input1ByForm>
                </Inputs>
                <Inputs1>
                  <Input1ByForm>
                    <PhoneNumber>{`${t('form.select3')}`}</PhoneNumber>
                    <Select
                      id="subBrand"
                      name="subBrand"
                      onChange={handleChange}
                      value={formData.subBrand}
                    >
                      <Option value="">{`${t('form.select3')}`}</Option>
                      {Array.isArray(data.subBrand) &&
                      data.subBrand.length > 0 ? (
                        data.subBrand.map((item, index) => (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        ))
                      ) : (
                        <Option disabled>{`${t(
                          'form.optionSubBrand'
                        )}`}</Option>
                      )}
                    </Select>
                    {validationErrors.subBrand && (
                      <ErrorMessage>{`${t(
                        'errors.fileRequired'
                      )}`}</ErrorMessage>
                    )}
                  </Input1ByForm>
                  <Input1ByForm>
                    <PhoneNumber>{`${t('form.select4')}`}</PhoneNumber>
                    <Select
                      id="descripcion"
                      name="descripcion"
                      onChange={handleChange}
                      value={formData.descripcion}
                    >
                      <Option value="">{`${t('form.optionSelect')}`}</Option>
                      {Array.isArray(data.descripcion) &&
                      data.descripcion.length > 0 ? (
                        data.descripcion.map((item, index) => (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        ))
                      ) : (
                        <Option disabled>{`${t(
                          'form.optionDescription'
                        )}`}</Option>
                      )}
                    </Select>
                    {validationErrors.descripcion && (
                      <ErrorMessage>{`${t(
                        'errors.fileRequired'
                      )}`}</ErrorMessage>
                    )}
                  </Input1ByForm>
                </Inputs1>
              </Inputs2>
              <Radios>
                <Link>{`${t('form.subtitle')}`}</Link>
                <Content2>
                  <Column4>
                    <FormControlLabel
                      label="Quálitas"
                      control={
                        <Checkbox
                          color="primary"
                          name="Qualitas"
                          onChange={handleCheckboxChange}
                        />
                      }
                    />
                    <FormControlLabel
                      label="AXA"
                      control={
                        <Checkbox
                          color="primary"
                          name="AXA"
                          onChange={handleCheckboxChange}
                        />
                      }
                    />
                    <FormControlLabel
                      label="GNP"
                      control={
                        <Checkbox
                          color="primary"
                          name="GNP"
                          onChange={handleCheckboxChange}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Chubb"
                      control={
                        <Checkbox
                          color="primary"
                          name="Chubb"
                          onChange={handleCheckboxChange}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Atlas"
                      control={
                        <Checkbox
                          color="primary"
                          name="Atlas"
                          onChange={handleCheckboxChange}
                        />
                      }
                    />
                  </Column4>
                  <Column4>
                    <FormControlLabel
                      label="HDI"
                      control={
                        <Checkbox
                          color="primary"
                          name="HDI"
                          onChange={handleCheckboxChange}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Mapfre"
                      control={
                        <Checkbox
                          color="primary"
                          name="Mapfre"
                          onChange={handleCheckboxChange}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Ana Seguros"
                      control={
                        <Checkbox
                          color="primary"
                          name="Ana"
                          onChange={handleCheckboxChange}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Inbursa"
                      control={
                        <Checkbox
                          color="primary"
                          name="Inbursa"
                          onChange={handleCheckboxChange}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Zurich"
                      control={
                        <Checkbox
                          color="primary"
                          name="Zurich"
                          onChange={handleCheckboxChange}
                        />
                      }
                    />
                  </Column4>
                </Content2>
                {validationErrors.selectedInsurances && (
                  <ErrorMessage>
                    {`${t('errors.insuranceRequired')}`}
                  </ErrorMessage>
                )}
              </Radios>
              <StyleprimarySmallfalseDa
                variant="contained"
                onClick={() => handleSubmit()}
                disabled={
                  !areRequiredFieldsFilled(formData, [
                    'year',
                    'brand',
                    'subBrand',
                    'descripcion',
                    'selectedInsurances',
                  ])
                }
              >
                {`${t('form.button')}`}
              </StyleprimarySmallfalseDa>
            </FormControl>
          )}
          {isFormVisible.byForm && data?.formDataResult && (
            <FormControl>
              <Inputs2>
                <Heading>{`${t('formResults.title')}`}</Heading>
                <InputByForm2>
                  <InputsResults>
                    {data.formDataResult?.results &&
                      Object.entries(data.formDataResult.results).map(
                        ([key, value]) => (
                          <InputByForm key={key}>
                            <Subheading>{key}</Subheading>
                            {value.AMIS ? (
                              <>
                                <FirstName>
                                  {`${t('formResults.clave')}`}: {value.AMIS}
                                </FirstName>
                                <FirstName>
                                  {`${t('formResults.vehicle')}`}:{' '}
                                  {value.MODELO} {value.MARCA} {value.SUBMARCA}
                                </FirstName>
                                <FirstName>
                                  {`${t('formResults.description')}`}:{' '}
                                  {value.DESCRIPCION}
                                </FirstName>
                              </>
                            ) : (
                              <ErrorMessage>
                                {`${t('errors.noResults')}`} <br />{' '}
                                {`${t('errors.noResults2')}`}
                              </ErrorMessage>
                            )}
                          </InputByForm>
                        )
                      )}
                  </InputsResults>
                  <InputsResults>
                    <InputByForm>
                      <FirstName>{`${t(
                        'formResults.effectivenessPercentage'
                      )}`}</FirstName>
                      <Heading>{efectividad}</Heading>
                    </InputByForm>
                    <InputByForm>
                      <FirstName>{`${t(
                        'formResults.foundMatches'
                      )}`}</FirstName>
                      <Heading>{coincidencias}</Heading>
                    </InputByForm>
                    <InputByForm>
                      <FirstName>{`${t(
                        'formResults.responseTime'
                      )}`}</FirstName>
                      <Heading>{tiempoRespuesta}</Heading>
                    </InputByForm>
                  </InputsResults>
                </InputByForm2>
              </Inputs2>
              <StyleprimarySmallfalseDa
                variant="contained"
                onClick={handleOnClickForm}
              >
                {`${t('formResults.button')}`}
              </StyleprimarySmallfalseDa>
            </FormControl>
          )}
          {isFormVisible.vehicleKey && !data?.vehicleDataResult && (
            <FormControl>
              <Inputs2>
                <PhoneNumber>{`${t('formVehicle.title')}`}</PhoneNumber>
                <Inputs>
                  <Input>
                    <PhoneNumber>{`${t('formVehicle.input1')}`}</PhoneNumber>
                    <Input2
                      id="vehicleKey"
                      name="vehicleKey"
                      placeholder={`${t('formVehicle.inputPlaceholder')}`}
                      onChange={handleChangeVehicle}
                      value={vehicleData.vehicleKey}
                    />
                    {validationErrorsVehicle.vehicleKey && (
                      <ErrorMessage>
                        {`${t('errors.fileRequired')}`}
                      </ErrorMessage>
                    )}
                  </Input>
                  <Input>
                    <PhoneNumber>{`${t('formVehicle.input2')}`}</PhoneNumber>
                    <Input2
                      id="year"
                      name="year"
                      placeholder={`${t('formVehicle.inputPlaceholder')}`}
                      onChange={handleChangeVehicle}
                      value={vehicleData.year}
                    />
                    {validationErrorsVehicle.year && (
                      <ErrorMessage>
                        {`${t('errors.yearValid')}`} ({new Date().getFullYear()}{' '}
                        - {new Date().getFullYear() - 20}).
                      </ErrorMessage>
                    )}
                  </Input>
                  <Input1>
                    <PhoneNumber>{`${t('formVehicle.select')}`}</PhoneNumber>
                    <Select
                      id="insuranceCompany"
                      name="insuranceCompany"
                      onChange={handleChangeVehicle}
                      value={vehicleData.insuranceCompany}
                    >
                      <Option value="">{`${t(
                        'formVehicle.optionSelect'
                      )}`}</Option>
                      <Option value="qualitas">Quálitas</Option>
                      <Option value="axa">AXA</Option>
                      <Option value="gnp">GNP</Option>
                      <Option value="chubb">Chubb</Option>
                      <Option value="atlas">Atlas</Option>
                      <Option value="hdi">HDI</Option>
                      <Option value="mapfre">Mapfre</Option>
                      <Option value="ana">Ana Seguros</Option>
                      <Option value="inbursa">Inbursa</Option>
                      <Option value="zurich">Zurich</Option>
                    </Select>
                    {validationErrorsVehicle.insuranceCompany && (
                      <ErrorMessage>
                        {`${t('errors.fileRequired')}`}
                      </ErrorMessage>
                    )}
                  </Input1>
                </Inputs>
              </Inputs2>
              <Radios>
                <Link>{`${t('form.subtitle')}`}</Link>
                <Content2>
                  <Column4>
                    <FormControlLabel
                      label="Quálitas"
                      control={
                        <Checkbox
                          color="primary"
                          name="Qualitas"
                          onChange={handleCheckboxChangeVehicle}
                        />
                      }
                    />
                    <FormControlLabel
                      label="AXA"
                      control={
                        <Checkbox
                          color="primary"
                          name="AXA"
                          onChange={handleCheckboxChangeVehicle}
                        />
                      }
                    />
                    <FormControlLabel
                      label="GNP"
                      control={
                        <Checkbox
                          color="primary"
                          name="GNP"
                          onChange={handleCheckboxChangeVehicle}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Chubb"
                      control={
                        <Checkbox
                          color="primary"
                          name="Chubb"
                          onChange={handleCheckboxChangeVehicle}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Atlas"
                      control={
                        <Checkbox
                          color="primary"
                          name="Atlas"
                          onChange={handleCheckboxChangeVehicle}
                        />
                      }
                    />
                  </Column4>
                  <Column4>
                    <FormControlLabel
                      label="HDI"
                      control={
                        <Checkbox
                          color="primary"
                          name="HDI"
                          onChange={handleCheckboxChangeVehicle}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Mapfre"
                      control={
                        <Checkbox
                          color="primary"
                          name="Mapfre"
                          onChange={handleCheckboxChangeVehicle}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Ana Seguros"
                      control={
                        <Checkbox
                          color="primary"
                          name="Ana"
                          onChange={handleCheckboxChangeVehicle}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Inbursa"
                      control={
                        <Checkbox
                          color="primary"
                          name="Inbursa"
                          onChange={handleCheckboxChangeVehicle}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Zurich"
                      control={
                        <Checkbox
                          color="primary"
                          name="Zurich"
                          onChange={handleCheckboxChangeVehicle}
                        />
                      }
                    />
                  </Column4>
                </Content2>
                {validationErrorsVehicle.selectedInsurances && (
                  <ErrorMessage>
                    {`${t('errors.insuranceRequired')}`}
                  </ErrorMessage>
                )}
              </Radios>
              <StyleprimarySmallfalseDa
                variant="contained"
                onClick={() => handleSubmit()}
                disabled={
                  !areRequiredFieldsFilled(vehicleData, [
                    'vehicleKey',
                    'year',
                    'insuranceCompany',
                    'selectedInsurances',
                  ])
                }
              >
                {`${t('form.button')}`}
              </StyleprimarySmallfalseDa>
            </FormControl>
          )}
          {isFormVisible.vehicleKey && data?.vehicleDataResult && (
            <FormControl>
              <Inputs2>
                <Heading>{`${t('formVehicleResults.title')}`}</Heading>
                <InputByForm2>
                  <InputsResults>
                    {data.vehicleDataResult?.results &&
                      Object.entries(data.vehicleDataResult.results).map(
                        ([key, value]) => (
                          <InputByForm key={key}>
                            <Subheading>{key}</Subheading>
                            {value.AMIS ? (
                              <>
                                <FirstName>
                                  {`${t('formVehicleResults.clave')}`}:{' '}
                                  {value.AMIS}
                                </FirstName>
                                <FirstName>
                                  {`${t('formVehicleResults.vehicle')}`}:{' '}
                                  {value.MODELO} {value.MARCA} {value.SUBMARCA}
                                </FirstName>
                                <FirstName>
                                  {`${t('formVehicleResults.description')}`}:{' '}
                                  {value.DESCRIPCION}
                                </FirstName>
                              </>
                            ) : (
                              <ErrorMessage>
                                {`${t('errors.noResults')}`} <br />{' '}
                                {`${t('errors.noResults2')}`}
                              </ErrorMessage>
                            )}
                          </InputByForm>
                        )
                      )}
                  </InputsResults>
                  <InputsResults>
                    <InputByForm>
                      <FirstName>{`${t(
                        'formVehicleResults.effectivenessPercentage'
                      )}`}</FirstName>
                      <Heading>{efectividad}</Heading>
                    </InputByForm>
                    <InputByForm>
                      <FirstName>{`${t(
                        'formVehicleResults.foundMatches'
                      )}`}</FirstName>
                      <Heading>{coincidencias}</Heading>
                    </InputByForm>
                    <InputByForm>
                      <FirstName>{`${t(
                        'formVehicleResults.responseTime'
                      )}`}</FirstName>
                      <Heading>{tiempoRespuesta}</Heading>
                    </InputByForm>
                  </InputsResults>
                </InputByForm2>
              </Inputs2>
              <StyleprimarySmallfalseDa
                variant="contained"
                onClick={handleOnClickForm}
              >
                {`${t('formResults.button')}`}
              </StyleprimarySmallfalseDa>
            </FormControl>
          )}
        </Form>
      </Contact>
      <Cta1>
        <Column7>
          <Content8>
            <Heading>{`${t('contact.title')}`}</Heading>
            <Text1>{`${t('contact.subtitle')}`}</Text1>
          </Content8>
          <Actions3>
            <StyleprimarySmallfalseDa2
              onClick={() => goToContact()}
              variant="contained"
            >{`${t('contact.button')}`}</StyleprimarySmallfalseDa2>
            <StyleprimarySmallfalseDa3
              onClick={() => goToCatalogo()}
              variant="outlined"
            >{`${t('contact.button2')}`}</StyleprimarySmallfalseDa3>
          </Actions3>
        </Column7>
      </Cta1>
      <Faq>
        <Column7>
          <Faqs>{`${t('faqs.title')}`}</Faqs>
          <Text1>{`${t('faqs.subtitle')}`}</Text1>
        </Column7>
        <Accordion>
          <AccordionItem>
            <QuestionContent onClick={() => toggleAnswerVisibility('q1')}>
              <Question>{`${t('faqs.accordions.question1')}`}</Question>
              <PlusIcon
                alt="icon-question"
                src={isAnswerVisible['q1'] ? '/v1/minus.svg' : '/v1/plus.svg'}
                onClick={() => toggleAnswerVisibility('q1')}
              />
            </QuestionContent>
            {isAnswerVisible['q1'] && (
              <AnswerContent>
                {`${t('faqs.accordions.answer1.text1')}`}{' '}
                <CatalogAIText>
                  {`${t('faqs.accordions.catalogAI')}`}
                </CatalogAIText>{' '}
                {`${t('faqs.accordions.answer1.text2')}`}{' '}
                <HereText onClick={() => goToContact()}>{`${t(
                  'faqs.accordions.here'
                )}`}</HereText>
              </AnswerContent>
            )}
          </AccordionItem>
          <AccordionItem>
            <QuestionContent onClick={() => toggleAnswerVisibility('q2')}>
              <Question>{`${t('faqs.accordions.question2')}`}</Question>
              <PlusIcon
                alt="icon-question"
                src={isAnswerVisible['q2'] ? '/v1/minus.svg' : '/v1/plus.svg'}
                onClick={() => toggleAnswerVisibility('q2')}
              />
            </QuestionContent>
            {isAnswerVisible['q2'] && (
              <AnswerContent>
                <CatalogAIText>
                  {`${t('faqs.accordions.catalogAI')}`}
                </CatalogAIText>{' '}
                {`${t('faqs.accordions.answer2')}`}
              </AnswerContent>
            )}
          </AccordionItem>
          <AccordionItem>
            <QuestionContent onClick={() => toggleAnswerVisibility('q3')}>
              <Question>{`${t('faqs.accordions.question3')}`}</Question>
              <PlusIcon
                alt="icon-question"
                src={isAnswerVisible['q3'] ? '/v1/minus.svg' : '/v1/plus.svg'}
                onClick={() => toggleAnswerVisibility('q3')}
              />
            </QuestionContent>
            {isAnswerVisible['q3'] && (
              <AnswerContent>
                <CatalogAIText>
                  {`${t('faqs.accordions.catalogAI')}`}
                </CatalogAIText>{' '}
                {`${t('faqs.accordions.answer3')}`}
              </AnswerContent>
            )}
          </AccordionItem>
          <AccordionItem>
            <QuestionContent onClick={() => toggleAnswerVisibility('q4')}>
              <Question>{`${t('faqs.accordions.question4')}`}</Question>
              <PlusIcon
                alt="icon-question"
                src={isAnswerVisible['q4'] ? '/v1/minus.svg' : '/v1/plus.svg'}
                onClick={() => toggleAnswerVisibility('q4')}
              />
            </QuestionContent>
            {isAnswerVisible['q4'] && (
              <AnswerContent>
                <CatalogAIText>
                  {`${t('faqs.accordions.catalogAI')}`}
                </CatalogAIText>{' '}
                {`${t('faqs.accordions.answer4')}`}
              </AnswerContent>
            )}
          </AccordionItem>
          <AccordionItem>
            <QuestionContent onClick={() => toggleAnswerVisibility('q5')}>
              <Question>{`${t('faqs.accordions.question5')}`}</Question>
              <PlusIcon
                alt="icon-question"
                src={isAnswerVisible['q5'] ? '/v1/minus.svg' : '/v1/plus.svg'}
                onClick={() => toggleAnswerVisibility('q5')}
              />
            </QuestionContent>
            {isAnswerVisible['q5'] && (
              <AnswerContent>{`${t('faqs.accordions.answer5')}`}</AnswerContent>
            )}
          </AccordionItem>
          <AccordionItem>
            <QuestionContent onClick={() => toggleAnswerVisibility('q6')}>
              <Question>{`${t('faqs.accordions.question6')}`}</Question>
              <PlusIcon
                alt="icon-question"
                src={isAnswerVisible['q6'] ? '/v1/minus.svg' : '/v1/plus.svg'}
                onClick={() => toggleAnswerVisibility('q6')}
              />
            </QuestionContent>
            {isAnswerVisible['q6'] && (
              <AnswerContent>{`${t('faqs.accordions.answer6')}`}</AnswerContent>
            )}
          </AccordionItem>
          <AccordionItem>
            <QuestionContent onClick={() => toggleAnswerVisibility('q7')}>
              <Question>{`${t('faqs.accordions.question7')}`}</Question>
              <PlusIcon
                alt="icon-question"
                src={isAnswerVisible['q7'] ? '/v1/minus.svg' : '/v1/plus.svg'}
                onClick={() => toggleAnswerVisibility('q7')}
              />
            </QuestionContent>
            {isAnswerVisible['q7'] && (
              <AnswerContent>
                {`${t('faqs.accordions.answer7.text1')}`}
                <br />
                {`${t('faqs.accordions.answer7.text2')}`}
              </AnswerContent>
            )}
          </AccordionItem>
        </Accordion>
        <Content10>
          <Content9>
            <StillHaveA>{`${t('questions.title')}`}</StillHaveA>
            <Text1>{`${t('questions.subtitle')}`}</Text1>
          </Content9>
          <StyleprimarySmallfalseDa3
            onClick={() => goToContact()}
            variant="outlined"
          >{`${t('questions.button')}`}</StyleprimarySmallfalseDa3>
        </Content10>
      </Faq>
    </DemoDesktopRoot>
  );
};

export default CatalogAIDemo;
