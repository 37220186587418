import { IFailureHandler } from '../../../core/interface/IFailureHandler';
import { ClientExecutionsRepository } from '../data';
import { GetClientUserExecutionsUseCase } from './useCases/getClientsUserExecutions.useCase';

/**
 * @typedef {import('../domain/entities/clientUserExecution.entity').ClientUserAgentExecutionResponseDTO} ClientUserAgentExecutionResponseDTO
 */


/**
 * @typedef {import('../domain/entities/clientUserExecution.entity').PaginationParams} PaginationParams
 */

export class ClientExecutionDomain {
  #failureHandler;
  #clientExecutionsRepository;
  #getClientUserExecutionsUseCase;
  constructor() {
    this.#failureHandler = new IFailureHandler();
    this.#clientExecutionsRepository = new ClientExecutionsRepository(
      this.#failureHandler
    );
    this.#getClientUserExecutionsUseCase = new GetClientUserExecutionsUseCase(
      this.#clientExecutionsRepository,
      this.#failureHandler
    );
  }

  /**
   * @inheritdoc
   * @description Get client user agent executions
   * @param {PaginationParams} params - Parameters for the function
   * @returns {Promise<ClientUserAgentExecutionResponseDTO>} responseDTO - Client user agent executions
   * @throws {Error} - If an error occurs while getting agents
   */
  getClientUserAgentExecutions = async (params) => {
    return await this.#getClientUserExecutionsUseCase.execute(params);
  };
}
