import styled from 'styled-components';

export const InputRoot = styled.textarea`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  padding: 16px 32px;
  min-height: 24px;
  max-height: 350px;
  height: auto;
  border: 1px solid #2240d9;
  background-color: transparent;
  border-radius: 8px;
  font-family: 'DM Sans';
  font-size: 16px;
  color: #003047;
  outline: none;
  resize: none;
  overflow-y: auto;
  line-height: 1.5;
  box-sizing: border-box;
  overflow-y: auto;

  &:disabled {
    cursor: not-allowed;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    height: 24px;
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
`;

export const AutoResizeTextArea = ({ value, onChange, ...props }) => {
  return (
    <InputRoot
      value={value}
      onChange={onChange}
      onInput={(e) => {
        e.target.style.height = 'auto';
        e.target.style.height = `${e.target.scrollHeight}px`;
      }}
      {...props}
    />
  );
};

export default AutoResizeTextArea;
