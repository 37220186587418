import { Link } from 'react-router-dom';
import styled from 'styled-components';

const COLORS = {
  BACKGROUND: '#0e1d39',
  WHITE: '#fff',
};

const BREAKPOINTS = {
  MOBILE: '768px',
  TABLET: '960px',
};

const Layout = {
  Root: styled.div`
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: ${COLORS.BACKGROUND};
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    font-size: 25.6px;
    color: ${COLORS.WHITE};
    font-family: 'DM Sans';

    @media screen and (max-width: ${BREAKPOINTS.MOBILE}) {
      width: auto;
      align-self: unset;
      height: 100%;
    }
  `,

  Container: styled.div`
    align-self: stretch;
    height: 575px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 72px 96px;
    box-sizing: border-box;
    gap: 50px 0px;
    text-align: right;
    font-size: 128px;

    @media screen and (max-width: ${BREAKPOINTS.MOBILE}) {
      gap: 50px 0px;
      align-items: center;
      justify-content: center;
      padding: 72px 30px;
    }
  `,

  Logo: styled.div`
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 40px;

    @media screen and (max-width: ${BREAKPOINTS.MOBILE}) {
      flex-direction: column;
      gap: 20px 40px;
    }
  `,

  RowContainer: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    padding: 20px;
    background-color: ${COLORS.BACKGROUND};
    border-radius: 8px;
  `,

  ColumnContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    padding: 20px;
    background-color: ${COLORS.BACKGROUND};
    border-radius: 8px;
  `,
};

const Typography = {
  LogoText: styled.b`
    height: 80px;
    position: relative;
    line-height: 120%;
    display: inline-block;

    @media screen and (max-width: ${BREAKPOINTS.TABLET}) {
      font-size: 100px;
    }

    @media screen and (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: 80px;
    }
  `,

  NavigationLink: styled(Link)`
    display: flex;
    align-items: center;
    gap: 10px;
    color: ${COLORS.WHITE};
    text-decoration: none;
    margin-top: 20px;
    font-size: 25.6px;

    &:hover {
      text-decoration: underline;
    }
  `,
};

const Images = {
  Logo: styled.img`
    height: 150px;
    width: 150px;
    object-fit: cover;
    min-width: 150px;
    max-width: 150px;

    @media screen and (max-width: ${BREAKPOINTS.MOBILE}) {
      width: 100%;
    }
  `,
};

const ArrowDown = ({ href, children }) => (
  <Typography.NavigationLink to={href}>{children}</Typography.NavigationLink>
);

const Swarm = () => {
  return (
    <Layout.Root>
      <Layout.Container>
        <Layout.Logo>
          <Images.Logo alt="Logo" src="/v1/group-1000002486@2x.png" />
          <Typography.LogoText>AutoSales Proposal</Typography.LogoText>
        </Layout.Logo>

        <Layout.ColumnContainer>
          <ArrowDown href="/v1/autosales/chat">
            Try Autosales (Old) Version
          </ArrowDown>
          <ArrowDown href="/v1/autosales/from-agents/execute">
            Try Autosales From Agents Manager
          </ArrowDown>
        </Layout.ColumnContainer>
      </Layout.Container>
    </Layout.Root>
  );
};

export default Swarm;
