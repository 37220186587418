import { createApiClient } from './apiClient';
import { API_BASE_URL, GRAPH_ENGINE_BACKEND_URL } from './apiConfig';
import logger from '../utils/helpers/logger';

const API_VERSION = 'v1';

const apiClient = createApiClient(`${API_BASE_URL}/${API_VERSION}`);

const graphEngineApiClient = createApiClient(
  `${GRAPH_ENGINE_BACKEND_URL}/apis/${API_VERSION}`
);

export const HttpMethod = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};

export class BaseService {
  client;
  failureHandler;

  constructor(client, failureHandler) {
    this.client = client;
    this.failureHandler = failureHandler;
  }

  async sendRequest(method, endpoint, data, params, config) {
    logger.info(
      `Sending Request: { method: ${method}, endpoint: ${endpoint}, data: ${JSON.stringify(
        data
      )}, params: ${JSON.stringify(params)} }`
    );
    const endpointWithVersion = `${endpoint}`;
    try {
      let response;
      if (method === HttpMethod.GET) {
        response = await this.client.get(endpointWithVersion, {
          params,
          ...config,
        });
      } else {
        response = await this.client[method](endpointWithVersion, data, {
          params,
          ...config,
        });
      }

      const responseData = response.data;
      const responseError = responseData && responseData.error;

      if (responseError) {
        logger.error(
          'Response error in sendRequest:',
          JSON.stringify(responseData, null, 2)
        );
        throw this.failureHandler.mapErrorToException(responseData);
      }
      return response.data;
    } catch (error) {
      console.error('Error in sendRequest:', error);
      throw error;
    }
  }

  async get(endpoint, params, config) {
    return this.sendRequest(
      HttpMethod.GET,
      endpoint,
      undefined,
      params,
      config
    );
  }

  async post(endpoint, body, config) {
    return this.sendRequest(HttpMethod.POST, endpoint, body, undefined, config);
  }

  async put(endpoint, body, config) {
    return this.sendRequest(HttpMethod.PUT, endpoint, body, undefined, config);
  }

  async patch(endpoint, body, config) {
    return this.sendRequest(
      HttpMethod.PATCH,
      endpoint,
      body,
      undefined,
      config
    );
  }

  async delete(endpoint, id, body, config) {
    const endpointWithId = `${endpoint}/${id}`;
    return this.sendRequest(
      HttpMethod.DELETE,
      endpointWithId,
      body,
      undefined,
      config
    );
  }

  async update(endpoint, id, body, config) {
    const endpointWithId = `${endpoint}/${id}`;
    return this.sendRequest(
      HttpMethod.PUT,
      endpointWithId,
      body,
      undefined,
      config
    );
  }
}

export class ApiService extends BaseService {
  constructor(failureHandler) {
    super(apiClient, failureHandler);
  }
}

export class GraphEngineApiService extends BaseService {
  constructor(failureHandler) {
    super(graphEngineApiClient, failureHandler);
  }
}
