import styled from 'styled-components';

export const SelectorContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const InputField = styled.input`
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid var(--color-stroke, #d0d5dd);
  background-color: transparent;
  padding: 0.625rem 1rem;
  padding-right: 2.5rem;
  color: #000;
  outline: none;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  line-height: 1.5;

  &:focus {
    border-color: var(--color-primary, #007bff);
  }

  &::placeholder {
    color: #667085;
    font-size: 14px;
  }

  @media (prefers-color-scheme: dark) {
    border-color: var(--color-form-strokedark, #374151);
    background-color: white;
    color: #374151;

    &:focus {
      border-color: var(--color-primary, #007bff);
    }
  }
`;

export const LoadingIndicator = styled.span`
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #667085;
  font-size: 12px;
`;

export const SuggestionsList = styled.ul`
  position: ${(props) => (props.dropdownOverflows ? 'absolute' : 'relative')};
  width: 100%;
  z-index: 9999;
  margin-top: 0.25rem;
  max-height: 150px;
  overflow-y: auto;
  border-radius: 0.375rem;
  border: 1px solid #e5e7eb;
  background-color: #fff;
  padding: 0.5rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

export const SuggestionItem = styled.li`
  cursor: pointer;
  border-radius: 0.375rem;
  padding: 0.5rem;
  list-style: none;
  font-size: 14px;
  line-height: 1.5;

  &:hover {
    background-color: var(--color-gray, #007bff);
    color: #fff;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background-color: transparent;
    color: inherit;
  }
`;

export const CreateNewItem = styled(SuggestionItem)`
  background-color: var(--color-gray, #f9fafb);
  opacity: 0.5;

  span {
    font-weight: 500;
  }

  &:hover {
    opacity: 1;
  }
`;
