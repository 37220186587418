import React, { useState } from 'react';
import { FaPlus, FaEdit, FaTrash } from 'react-icons/fa';
import {
  SidebarWrapper,
  NavElement,
  SidebarRoot,
  ProposalBuilder,
  RecienteParent,
  Reciente,
  PropuestaClienteWrapper,
  PropuestaCliente,
  RoundedDot,
  IconsWrapper,
  InputChangeName,
  LoaderSkeleton,
} from './Navbar.styles';
import { useExecuteAgentContext } from '../../../../contexts/ExecuteAgents.context';
import useGetClientExecution from '../../../../presentation/hooks/use-get-client-executions.hook';
import { useHistory } from 'react-router-dom';

const Navbar = () => {
  const history = useHistory();
  const { loadingExecutions: loading, clientExecutions } =
    useExecuteAgentContext();

  const {
    executeCallback: getClientExecutions,
    iterationSkip,
    hasMore,
    loading: loadingExecutions,
  } = useGetClientExecution();
  const handleClientClick = (execution) => {
    history.push(`/v1/autosales/from-agents/execution/${execution.id}`);
  };

  const beutifyExecutionId = (id = '') => {
    return `${id.slice(0, 4)}...${id.slice(-4)}`;
  };

  const handleSearchMoreExecutions = async () => {
    await getClientExecutions({ entriesPerPage: 100, skip: iterationSkip });
  };

  // const handleEditClick = (e, client) => {
  //   e.stopPropagation();
  //   setEditingClient(client);
  //   setNewClientName(client.name);
  // };

  // const handleDeleteClick = (e, clientId) => {
  //   e.stopPropagation();
  //   console.log('Delete client:', clientId);
  // };

  // const handleNameChange = (e) => {
  //   setNewClientName(e.target.value);
  // };

  // const handleNameSubmit = (e) => {
  //   e.preventDefault();
  //   console.log('Update client name:', editingClient.id, newClientName);
  //   setEditingClient(null);
  // };

  return (
    <SidebarWrapper>
      <SidebarRoot>
        <ProposalBuilder>
          Proposal Builder
          <NavElement
            onClick={() => history.push('/v1/autosales/from-agents/execute')}
          >
            <FaPlus color="#fff" size={24} />
          </NavElement>
        </ProposalBuilder>

        <RecienteParent>
          <Reciente>Recent Executions</Reciente>

          {loading ? (
            <>
              <LoaderSkeleton />
              <LoaderSkeleton />
              <LoaderSkeleton />
            </>
          ) : (
            clientExecutions &&
            clientExecutions.length > 0 &&
            clientExecutions.map((execution) => (
              <PropuestaClienteWrapper
                key={execution.id}
                onClick={() => handleClientClick(execution)}
              >
                <PropuestaCliente>
                  {beutifyExecutionId(execution.id)}
                </PropuestaCliente>

                {/* <IconsWrapper>
                  <FaEdit
                    className="edit-icon"
                    onClick={(e) => handleEditClick(e, execution)}
                    style={{ marginRight: '8px' }}
                  />
                  <FaTrash
                    className="delete-icon"
                    onClick={(e) => handleDeleteClick(e, execution.id)}
                  />
                </IconsWrapper> */}
              </PropuestaClienteWrapper>
            ))
          )}
          {loadingExecutions ? (
            <>
              <LoaderSkeleton />
              <LoaderSkeleton />
              <LoaderSkeleton />
            </>
          ) : (
            hasMore && (
              <PropuestaClienteWrapper onClick={handleSearchMoreExecutions}>
                <PropuestaCliente>Load more</PropuestaCliente>
              </PropuestaClienteWrapper>
            )
          )}
        </RecienteParent>
      </SidebarRoot>
    </SidebarWrapper>
  );
};

export default Navbar;
