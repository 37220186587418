import styled from 'styled-components';

const ExecutionsStyles = {
  Skeleton: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px 0;
    width: 100%;
    height: 100vh;
  `,

  ColumnsWrapper: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px 0px;
    width: 100%;
    max-width: 900px;
    box-sizing: border-box;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    color: #0e1d39;
    background-color: #f5f7fa;
  `,

  RowContainer: styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0px 0px;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 85px 50px 50px 50px;
    overflow-y: auto;

    color: #0e1d39 !important;
    background-color: #f5f7fa !important;
  `,

  ExecutionNavContainer: styled.nav`
    position: fixed;
    z-index: 10;
    right: 50px;
    top: 145px;
    display: flex;
    flex-direction: column;
    width: 261.741px;
    height: 85vh;
    padding: 15.262px 12.21px var(--spacing-none, 0px) 12.21px;

    border-radius: 9.157px;
    border: 0.763px solid rgba(0, 0, 0, 0.05);
    background: var(--Colors-Background-bg-primary, #fff);
    box-shadow: 0px 3.602px 3.602px 0px rgba(0, 0, 0, 0.25);
  `,

  NavbarTitleWrapper: styled.div`
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 9.157px;
    width: 100%;
    padding: 9.157px 0px 9.157px 0px;
    align-self: stretch;
  `,

  NavbarPrimaryTitle: styled.h2`
    color: var(--colors-text-text-primary-900, #101828);
    font-family: Inter, sans-serif;
    font-size: 13.736px;
    font-style: normal;
    font-weight: 600;
    line-height: 21.367px;
  `,

  NavbarSubTitle: styled.p`
    color: var(--colors-text-text-tertiary-600, #475467);
    font-family: Inter, sans-serif;
    font-size: 10.683px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.262px;
  `,

  NavElementsWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 9.157px;
    width: 100%;
    padding: 9.157px 0px 9.157px 0px;
    align-self: stretch;
    overflow-y: auto;
  `,

  NavItem: styled.a`
    display: flex;
    padding: var(--spacing-xl, 16px);
    align-items: center;
    gap: var(--spacing-xs, 4px);
    width: 100%;
    border-radius: 9.184px;
    border: 0.763px solid ${(props) => props.borderColor || '#e0f0ff'};
    background: var(--Colors-Background-bg-primary, #fff);
    cursor: pointer;
    text-decoration: none;

    &.active {
      border-color: #2240d9;
    }
  `,

  NavElementImgWrapper: styled.div`
    display: flex;
    width: 24.419px;
    height: 24.419px;
    padding: 6.105px;
    justify-content: center;
    align-items: center;
    border-radius: var(--radius-full, 7630.184px);
    background: var(--SECONDARY-ALICE-BLUE, #e0f0ff);
  `,

  NavElementText: styled.p`
    flex: 1;
    display: flex;
    align-items: center;
    color: var(--colors-text-text-secondary-700, #344054);
    font-family: Inter, sans-serif;
    font-size: 10.683px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.262px;
  `,

  SectionTitle: styled.h2`
    color: var(--colors-text-text-primary-900, #101828);
    font-family: 'DM Sans', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 16px;
    padding-bottom: 8px;
    border-bottom: 1px solid #eaecf0;
  `,
};

export default ExecutionsStyles;
