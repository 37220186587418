import { useState } from 'react';
import { AgentsManagerDomain } from '../../../../modules/agents/domain';

/**
 * @typedef {import('../../../../modules/agents/domain/entities/angets.entity').AgentEntity} AgentEntity
 */

/**
 * @typedef {Object} SearchParams
 * @property {string} [search] - ID del cliente
 * @property {number} [skip] - ID del agente
 * @property {number} [entriesPerPage] - Versión del agente
 */

/**
 * @callback ExecuteAgentCallback
 * @param {SearchParams} searchParams - Parámetros de búsqueda
 * @returns {Promise<AgentEntity[]>} - Lista de agentes
 */

/**
 * @typedef {Object} GetAgentVersionsReturn
 * @property {ExecuteAgentCallback} executeCallback - Función para ejecutar la búsqueda de versiones de agentes
 * @property {AgentEntity[]} agents - Lista de agentes
 * @property {boolean} loading - Estado de carga
 * @property {Error|null} error - Error, si existe
 */

/**
 * Hook para obtener las versiones de los agentes
 * @returns {GetAgentVersionsReturn} Objeto que contiene datos de agentes y función de búsqueda
 */
export const useGetAgentVersions = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [agents, setAgents] = useState([]);
  const repository = new AgentsManagerDomain();

  /**
   * @type {ExecuteAgentCallback}
   */
  const executeCallback = async (searchParams) => {
    setLoading(true);
    setError(null);
    try {
      const result = await repository.getAgents(searchParams);
      if (!result || result.agents.length === 0) {
        setAgents([]);
        return [];
      }
      setAgents(result.agents);
      return result.agents;
    } catch (err) {
      const errorObj =
        err instanceof Error ? err : new Error('Error desconocido');
      setError(errorObj);
      throw errorObj;
    } finally {
      setLoading(false);
    }
  };

  return {
    executeCallback,
    agents,
    loading,
    error,
  };
};
