import { IFailureHandler } from '../../../../core/interface/IFailureHandler';
import { AgentsPorts } from '../ports/agents.ports';
import { IUseCase } from '../../../../core/interface/IUseCase';

export class ExecuteAgentUseCase extends IUseCase {
  agentsRepository;
  failureHandler;

  /**
   * Constructor for the GetAgentUseCase class
   * @param {AgentsPorts} agentsRepository - The repository to get agents
   * @param {IFailureHandler} failureHandler - The failure handler to handle errors
   */
  constructor(agentsRepository, failureHandler) {
    super();
    this.agentsRepository = agentsRepository;
    this.failureHandler = failureHandler;
  }
  /**
   * @inheritdoc
   * @param {Object} params - The parameters to execute the agent
   * @param {string} params.agentId - The ID of the agent to execute
    * @param {number} params.flowId - The ID of the flow to execute
    * @param {string} params.input - The input data for the execution
   * @param
   */
  async execute(params) {
    try {
      const graphExecution = await this.agentsRepository.executeGraph(params);
      return graphExecution;
    } catch (error) {
      this.failureHandler.logAndMapExceptionToFailure(error);
      throw error;
    }
  }
}
