import React, { useEffect, useState, useRef } from 'react';
import useDebounce from '../../hooks/useDebounce';
import {
  SelectorContainer,
  InputField,
  LoadingIndicator,
  SuggestionsList,
  SuggestionItem,
  CreateNewItem,
} from './DebounceSelector.styles';

/**
 * A debounced search input with dropdown suggestions
 * @param {Object} props - Component props
 * @param {string} props.name - Input name attribute
 * @param {string} [props.initialValue] - Initial input value
 * @param {Function} [props.onChange] - Callback when input changes
 * @param {string} props.placeholder - Input placeholder text
 * @param {number} [props.debounceTime=500] - Debounce delay in milliseconds
 * @param {boolean} [props.isLoading] - Whether search is in progress
 * @param {Function} [props.onSearch] - Callback to fetch suggestions
 * @param {Function} [props.onSelect] - Callback when an item is selected
 * @param {Function} [props.onKeyDownCallback] - Callback for Enter key press
 * @param {string} [props.selectField="name"] - Object field to display in suggestions
 * @param {boolean} [props.allowCreate=false] - Allow creating new items
 * @param {boolean} [props.dropdownOverflows=true] - Whether dropdown can overflow container
 */
const DebounceSelector = ({
  name,
  onChange,
  placeholder,
  initialValue = '',
  debounceTime = 500,
  onSearch,
  onSelect,
  onKeyDownCallback,
  selectField = 'name',
  allowCreate = false,
  dropdownOverflows = true,
}) => {
  const [displayValue, setDisplayValue] = useState(initialValue);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialValue, setIsInitialValue] = useState(true);
  const [selectedValue, setSelectedValue] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const searchRequestRef = useRef(null);
  const previousSearchRef = useRef('');
  const initialValueRef = useRef(initialValue);

  useEffect(() => {
    if (
      initialValue !== initialValueRef.current &&
      initialValue !== displayValue
    ) {
      setDisplayValue(initialValue);
      setSelectedValue(initialValue || null);
      initialValueRef.current = initialValue;
    }
  }, [initialValue, displayValue]);

  const debouncedValue = useDebounce({
    value: displayValue,
    delay: debounceTime,
  });

  const setIsInitialValueToFalse = () => {
    if (isInitialValue) {
      setIsInitialValue(false);
    }
  };

  useEffect(() => {
    if (
      debouncedValue &&
      !selectedValue &&
      onSearch &&
      debouncedValue !== previousSearchRef.current
    ) {
      previousSearchRef.current = debouncedValue;

      if (searchRequestRef.current) {
        searchRequestRef.current.abort();
      }

      const abortController = new AbortController();
      searchRequestRef.current = abortController;

      const searchPromise = onSearch(debouncedValue);

      setIsLoading(true);

      searchPromise
        .then((result) => {
          if (searchRequestRef.current === abortController) {
            if (Array.isArray(result)) {
              setSuggestions(result);
            } else if (result && result.data) {
              setSuggestions(result.data);
            } else {
              setSuggestions([]);
            }
          }
        })
        .catch((error) => {
          if (error.name !== 'AbortError') {
            setSuggestions([]);
          }
        })
        .finally(() => {
          setIsLoading(false);
          if (searchRequestRef.current === abortController) {
            searchRequestRef.current = null;
          }
        });
    } else if (!debouncedValue) {
      setSuggestions([]);
    }
  }, [debouncedValue, selectedValue, onSearch]);

  // Effect to add event listeners for click and keydown events to close panel if clicked outside or pressed Escape
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        !e.target.closest(`#${name}`) &&
        !e.target.closest('.suggestions-list')
      ) {
        setSuggestions([]);
      }
    };
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        setSuggestions([]);
      }
    };
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [name]);

  const handleInputChange = (e) => {
    setIsInitialValueToFalse();
    setDisplayValue(e.target.value);
    setSelectedValue(null);
    onChange?.(e);
  };

  const handleSelect = (value, suggestion) => {
    setIsInitialValueToFalse();
    setDisplayValue(value);
    setSelectedValue(value);
    setSuggestions([]);
    onSelect?.(value, suggestion);
    onChange?.({
      target: { name, value },
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (!allowCreate) {
        return e.preventDefault();
      }
      setIsInitialValueToFalse();
      setSelectedValue(displayValue);
      onKeyDownCallback?.();
      setSuggestions([]);
      onSelect?.(displayValue);
      onChange?.({
        target: { name, value: displayValue },
      });
    }
  };

  return (
    <SelectorContainer>
      <InputField
        type="text"
        id={name}
        name={name}
        value={displayValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
      />

      {!isInitialValue &&
        !selectedValue &&
        (suggestions.length > 0 || debouncedValue) && (
          <SuggestionsList dropdownOverflows={dropdownOverflows}>
            {/* Loading indicator */}
            {isLoading && <SuggestionItem disabled>Loading...</SuggestionItem>}

            {/* If no suggestions found */}
            {!isLoading && suggestions.length === 0 && (
              <SuggestionItem disabled>No results found</SuggestionItem>
            )}

            {/* First item for creating a new value */}
            {debouncedValue && allowCreate && (
              <CreateNewItem onClick={() => handleSelect(debouncedValue)}>
                Create <span>{`"${debouncedValue}"`}</span>
              </CreateNewItem>
            )}

            {/* Rest of the search suggestions */}
            {suggestions.map((suggestion, index) => (
              <SuggestionItem
                key={index}
                onClick={() =>
                  handleSelect(suggestion[selectField], suggestion)
                }
              >
                {suggestion[selectField]}
              </SuggestionItem>
            ))}
          </SuggestionsList>
        )}
    </SelectorContainer>
  );
};

export default DebounceSelector;
