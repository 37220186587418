// src/modules/agents/data/repositories/executeGraph.repository.ts

import { GraphEngineApiService } from "../../../../core/api/apiService";
import GRAPH_ENGINE_ENDPOINTS from "../endpoints/graphEngineEndpoints.endpoints";


export class ExecuteGraphRepository extends GraphEngineApiService {
  constructor(failureHandler) {
    super(failureHandler);
    this.failureHandler = failureHandler;
  }

  async executeGraph(
    executeDto,
  ) {
    const responseData = await this.post(
      GRAPH_ENGINE_ENDPOINTS.executeGraph,
      { ...executeDto, is_test: true },
    );

    return responseData;
  }
}
