import styled from 'styled-components';
import Navbar from './Nav/Navbar';
import ClientSelector from '../../../shared/ClientSelector';
import { useEffect, useRef } from 'react';

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0px;
  height: 100dvh;
  width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0px;
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
  position: sticky;
  top: 0;
  z-index: 100;
`;

const ColumnContentWithClientSelector = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  scroll-padding-top: 125px;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  scroll-padding-top: 125px;
  overflow: hidden;
`;

const AgentsManagerLayout = ({ children }) => {
  const titleRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (titleRef.current && contentRef.current) {
      const titleHeight = titleRef.current.offsetHeight;
      contentRef.current.style.scrollPaddingTop = `${titleHeight}px`;

      document.documentElement.style.setProperty(
        '--scroll-padding',
        `${titleHeight}px`
      );
    }
  }, []);

  return (
    <LayoutWrapper>
      <Navbar />
      <ColumnContentWithClientSelector ref={contentRef}>
        <TitleContainer ref={titleRef}>
          <h4>{process.env.REACT_APP_PROJECT_NAME || 'AUTOSALES'} Proposal</h4>
          <ClientSelector />
        </TitleContainer>
        <ContentContainer>{children}</ContentContainer>
      </ColumnContentWithClientSelector>
    </LayoutWrapper>
  );
};

export default AgentsManagerLayout;
