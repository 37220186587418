import { IFailureHandler } from '../../../core/interface/IFailureHandler';
import { AgentsRepository } from '../data';
import { ExecuteAgentUseCase } from './useCases/executeAgent.useCase';
import { GetAgentUseCase } from './useCases/getAgents.useCase';
import { GetAgentsWithFlowsUseCase } from './useCases/getAgentsWithFlows.useCase';
import { GetClientsByNameUseCase } from './useCases/getClientsByName.useCase';

/**
 * @typedef {import('../../users/domain/entities/users.entity').ClientEntity} ClientEntity
 * @typedef {import('../domain/entities/angets.entity').AgentEntity} AgentEntity
 */


/**
 * @typedef {Object} GetAgentsResults
 * @property {AgentEntity[]} agents - The list of agents
 * @property {number} pagination - Number of pages
 */

export class AgentsManagerDomain {
  #agentsRepository = new AgentsRepository();
  #failureHandler;
  #getAgentsRepository;
  #getAgentsFlowsRepository;
  #getClientsByNameRepository;
  #executeGraphRepository;

  constructor() {
    this.#agentsRepository = new AgentsRepository();
    this.#failureHandler = new IFailureHandler();
    this.#getAgentsRepository = new GetAgentUseCase(
      this.#agentsRepository,
      this.#failureHandler
    );
    this.#getAgentsFlowsRepository = new GetAgentsWithFlowsUseCase(
      this.#agentsRepository,
      this.#failureHandler
    );
    this.#getClientsByNameRepository = new GetClientsByNameUseCase(
      this.#agentsRepository,
      this.#failureHandler
    );
    this.#executeGraphRepository = new ExecuteAgentUseCase(
      this.#agentsRepository,
      this.#failureHandler
    );
  }

  /**
   * @inheritdoc
   * @param {Object} params - The parameters to get agents
   * @param {string} [params.name] - The name of the agent to search for
   * @param {number} [params.skip] - The number of agents to skip
   * @param {number} [params.entriesPerPage] - The number of agents to return per page
   * @returns {Promise<GetAgentsResults>} - The result of the search
   * @throws {Error} - If an error occurs while getting agents
   */
  getAgents = async (params) => {
    return await this.#getAgentsRepository.execute(params);
  };

  /**
   * @inheritdoc
   * @param {Object} params - The parameters to get clients by name
   * @param {string} params.search - The name of the client to search for
   * @returns {Promise<ClientEntity[]>} - The result of the search
   */
  getClientsByName = async (params) => {
    return await this.#getClientsByNameRepository.execute(params);
  };

  /**
   * @inheritdoc
   * @param {string} id - The id of the agent to search for
   */
  getAgentWithFlows = async (id) => {
    return await this.#getAgentsFlowsRepository.execute(id);
  };

  /**
   * @inheritdoc
   * @param {string} id - The id of the agent to search for
   */
  getAgentById = async (id) => {
    return await this.#getAgentsFlowsRepository.execute(id);
  };

  /**
   * @inheritdoc
   * @param {Object} params - The parameters to execute the agent
   * @param {string} params.agentId - The ID of the agent to execute
   * @param {number} params.flowId - The ID of the flow to execute
   * @param {string} params.input - The input data for the execution
   */
  executeAgent = async (params) => {
    return await this.#executeGraphRepository.execute(params);
  };
}
