/**
 * @typedef {import('../entities/users.entity').UserEntity} UserEntity
 * @typedef {import('../entities/users.entity').ClientEntity} ClientEntity
 * @typedef {import('../entities/users.entity').AdminEntity} AdminEntity
 * @typedef {import('../entities/users.entity').Role} Role
 */

/**
 * @class Users ports
 * @description This file contains the UsersPorts class, which defines the users ports for the application.
 * It includes methods for login user account in Agent Manager Service.
 */
export class UsersPorts {
  /**
   * @description Login user account in Agent Manager Service
   * @param {Object} data - The data to login user account
   * @param {string} data.email - The user email
   * @param {string} data.password - The user password
   * @returns {Promise<UserEntity>} - The response from the server
   * @throws {Error} - If the login fails
   */
  async authenticate({ email, password }) {
    throw new Error('Method not implemented');
  }
}
