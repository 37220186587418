import { IUseCase } from '../../../../core/interface/IUseCase';
import { IFailureHandler } from '../../../../core/interface/IFailureHandler';
import { ClientUserExecutionPort } from '../ports/clientUserExecution.port';


/**
 * @typedef {import('../entities/clientUserExecution.entity').PaginationParams} PaginationParams
 */

export class GetClientUserExecutionsUseCase extends IUseCase {
  clientUserExecutionRepository;
  failureHandler;
  /**
   * Constructor for the GetClientUserExecutionsUseCase class
   * @param {ClientUserExecutionPort} clientUserExecutionRepository - The repository to get client user executions
   * @param {IFailureHandler} failureHandler - The failure handler to handle errors
   */
  constructor(clientUserExecutionRepository, failureHandler) {
    super();
    this.clientUserExecutionRepository = clientUserExecutionRepository;
    this.failureHandler = failureHandler;
  }

  /**
   * @description Get client user agent executions
   * @param {PaginationParams} params - Parameters for the function
   */
  async execute(params) {
    try {
      const response = await this.clientUserExecutionRepository.getClientUserAgentExecutions(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
}
