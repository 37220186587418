const AGENTS_ENDPOINTS = {
  getAgents: "agents",
  createAgent: "agents/create",
  getAgentsByFilters: "agents/filter",
  updateAgent: "agents/update",
  deleteAgent: "agents/delete",
  getAgentWithFlows: "agents",
  saveAgentFlowDraft: "agents/save-flow-draft",
  publishAgentFlowVersion: "agents/publish-flow-version",
  upsertWhatsappConfigAgent: "whatsapp/upsert-configure-webhook",
};

export default AGENTS_ENDPOINTS;
