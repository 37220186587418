import { IFailureHandler } from '../../../../core/interface/IFailureHandler';
import { AgentsPorts } from '../ports/agents.ports';
import { IUseCase } from '../../../../core/interface/IUseCase';

export class GetAgentsWithFlowsUseCase extends IUseCase {
  agentsRepository;
  failureHandler;

  /**
   * Constructor for the GetAgentUseCase class
   * @param {AgentsPorts} agentsRepository - The repository to get agents
   * @param {IFailureHandler} failureHandler - The failure handler to handle errors
   */
  constructor(agentsRepository, failureHandler) {
    super();
    this.agentsRepository = agentsRepository;
    this.failureHandler = failureHandler;
  }
  /**
   * @inheritdoc
   * @param {string} id - The id of the agent to search for
   */
  async execute(id = '') {
    try {
      const agentFlowById = await this.agentsRepository.getAgentWithFlows(id);
      return agentFlowById;
    } catch (error) {
      this.failureHandler.logAndMapExceptionToFailure(error);
      throw error;
    }
  }
}
