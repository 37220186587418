import { ClientUserExecutionPort } from '../domain/ports/clientUserExecution.port';
import { GetClientUserAgentExecutionsRepository } from './repositories/getClientUserExecutions.repository';

/**
 * @typedef {import('../domain/entities/clientUserExecution.entity').PaginationParams} PaginationParams
 * @typedef {import('../domain/entities/clientUserExecution.entity').ClientUserAgentExecutionResponseDTO} ClientUserAgentExecutionResponseDTO
 */

export class ClientExecutionsRepository extends ClientUserExecutionPort {
  constructor(failureHandler) {
    super(failureHandler);
    this.failureHandler = failureHandler;
    this.getClientUserAgentExecutionsRepository =
      new GetClientUserAgentExecutionsRepository(this.failureHandler);
  }

  /**
   * @inheritdoc
   * @description Get client user agent executions
   * @param {PaginationParams} params - Parameters for the function
   * @returns {Promise<ClientUserAgentExecutionResponseDTO>} responseDTO - Client user agent executions
   */
  async getClientUserAgentExecutions(params) {
    return await this.getClientUserAgentExecutionsRepository.getExecutions(params)
  }
}
