import { ApiService } from '../../../../core/api/apiService';
import { IFailureHandler } from '../../../../core/interface/IFailureHandler';
import { USERS_ENDPOINTS } from '../endpoints/users.endpoints';

export class LoginUserRepository extends ApiService {
  /**
   * Constructor for the LoginUserRepository class
   * @param {IFailureHandler} failureHandler - The failure handler to handle errors
   */
  constructor(failureHandler) {
    super(failureHandler);
    this.failureHandler = failureHandler;
  }
  /**
   *  @typedef {import('../../domain/entities/users.entity').UserEntity} UserEntity
   */

  /**
   * @inheritdoc
   * @param {Object} data - The data to authenticate the user
   * @param {string} data.email - The email of the user
   * @param {string} data.password - The password of the user
   * @returns {Promise<UserEntity>} - A promise that resolves when the authentication is complete
   * @throws {Error} - If the authentication fails
   */
  async authenticate({ email, password }) {
    try {
      return await this.post(`${USERS_ENDPOINTS.login}`, {
        email,
        password,
      });
    } catch (error) {
      return error.response?.data;
    }
  }
}
