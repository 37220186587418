const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:4137";
const BEARER_TOKEN = process.env.NEXT_PUBLIC_BEAR || "";
const GRAPH_ENGINE_BACKEND_URL =
  process.env.REACT_APP_GRAPH_ENGINE_BACKEND_URL || "http://localhost:2137";


export {
  API_BASE_URL,
  BEARER_TOKEN,
  GRAPH_ENGINE_BACKEND_URL,
};
