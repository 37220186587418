import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import React, { useState } from 'react';
import SwarmRoutes from './pages/SwarmAI';
import LayoutApp from './components/LayoutApp';
import ScrollToTop from './components/ScrollToTop';
import WhatsappButton from './components/WhatsappButton';
import useGetLocationIp from './hooks/LocationIp';
import { PaisContext } from './useContext/PaisContext';
import CatalogAIDemo from './pages/catalog-demo';
import Swarm from './pages/SwarmAI/pages/Swarm';

export default function Router() {
  const [paisNav, setPaisNav] = useState('');
  const [pais, setPais, isLoader] = useGetLocationIp(paisNav);

  return (
    <PaisContext.Provider
      value={{ pais, setPais, isLoader, paisNav, setPaisNav }}
    >
      <BrowserRouter>
        <LayoutApp>
          <ScrollToTop />
          <WhatsappButton />
          <Switch>
            <Route exact path='/' component={Swarm} />
            <Route exact path='/v1'>
              <Redirect to='/' />
            </Route>
            <Route path='/v1/catalogos-demo' component={CatalogAIDemo} />
            <Route path='/v1/swarm' component={SwarmRoutes} />
            <Route path='/v1/autosales' component={SwarmRoutes} />
            <Redirect to='/v1/error' />
          </Switch>
        </LayoutApp>
      </BrowserRouter>
    </PaisContext.Provider>
  );
}
