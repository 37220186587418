import { useEffect, useState } from 'react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import {
  downloadInPDF,
  downloadPPTX,
  fetchHistoryInOrderToRender,
  renameHistoryChat,
} from '../services/swarm.service';
import ReactMarkdown from 'react-markdown';
import Navbar from '../components/Navigation/Navbar';
import SVGComponent from '../components/SVGComponent';
import ProposalNavbar from '../components/Navigation/ProposalNav';
import AskAITooltip from '../components/AITooltip';
import Loader from '../components/Loader';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const ProposalStyles = createGlobalStyle`
body {
      margin: 0;
      line-height: normal;
      box-sizing: border-box;
    }

    .indice,
    .portada {
      position: relative;
    }

    .relative {
      position: relative;
      z-index: 1000;
    }

    .absolute {
      position: absolute;
      z-index: 1;
    }

    .left {
      left: 0;
    }

    .rigth {
      right: 0;
    }

    .top {
      top: 0;
    }

    .bottom {
      bottom: 0;
    }

    .z-0 {
      z-index: 0;
    }

    .z-1 {
      z-index: 1;
    }

    .w-full {
      width: 100%;
    }

    .h-full {
      height: 100vh;
    }

    .left-expand {
      left: -2cm;
    }

    .rigth-expand {
      right: -2cm;
    }

    .top-expand {
      top: -2cm;
    }

    .bottom-expand {
      bottom: -2cm;
    }

    .propuesta {
      margin: 0;
    }

    .logo-full-width {
      width: 100%;
      height: 50px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    .h11,
    .title {
      position: relative;
      line-height: 120%;
    }

    .h11 {
      font-size: 26.2px;
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      gap: 40px 0;
      font-size: 18px;
    }

    .content {
      align-self: stretch;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px;
      min-height: 125px;
    }

    .image-1-icon {
      width: 100%;
      position: relative;
      height: 622px;
      object-fit: cover;
    }

    .portada {
      position: relative;
      width: 100%;
      min-width: 595px;
      height: 100vh;
      min-height: 842px;
      position: relative;
      background-color: #fff;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      text-align: left;
      font-size: 34.3px;
      color: var(--primary-navy);
      font-family: var(--font-dm-sans);
      box-sizing: border-box;
    }

    .portada-img {
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 0;
    }

    .image-2-icon {
      width: 100%;
      position: absolute;
      margin: 0 !important;
      top: 0;
      left: -0.2px;
      height: 842px;
      object-fit: cover;
      z-index: 0;
    }

    .h12 {
      align-self: stretch;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      height: 100px;
      flex-shrink: 0;
      z-index: 1;
    }

    .h12,
    .h13 {
      position: relative;
      line-height: 120%;
    }

    .h1-parent {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 32px 0;
      z-index: 2;
      font-size: var(--font-size-mid);
    }

    .h114 {
      position: relative;
      font-size: 12px;
      line-height: 120%;
    }

    .h1-group,
    .indice {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .h1-group {
      align-self: stretch;
      justify-content: flex-start;
      padding: 0 0 6px;
      gap: 20px 0;
      z-index: 3;
      font-size: 16px;
    }

    .indice {
      width: 100%;
      height: 100vh;
      min-height: 842px;
      position: relative;
      background-color: var(--color-white);
      overflow: hidden;
      justify-content: space-between;
      text-align: left;
      font-size: 36.2px;
      color: var(--primary-navy);
      font-family: var(--font-dm-sans);
    }

    .pages {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 20px 0;
      width: 100%;

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      color: var(--primary-navy);
      font-family: var(--font-dm-sans);
      /* 18px */
      box-sizing: border-box;
    }

    .page-header {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      height: 50px;
      /* padding: 0px 50px; */
      flex-shrink: 0;
      box-sizing: border-box;
    }

    .logo-img {
      width: 18px;
      height: 16.5px;
    }

    .h11 {
      color: var(--PRIMARY-PRUSSIAN-BLUE, #003047);
      font-family: "DM Sans";
      font-size: 10.592px;
      font-style: normal;
      font-weight: 500;
      line-height: 12.711px;
      /* 120% */
      letter-spacing: -0.212px;
    }

    .pages h2,
    .pages h3,
    .pages h4,
    .pages h5,
    .pages h6 {
      font-family: "DM Sans", sans-serif;
      color: var(--PRIMARY-NAVY, #0E1D39);
      /* 20.446px */

      padding-bottom: 12px;
    }

    .pages h1 {
      color: var(--colors-text-text-primary-900, #101828);
      font-size: 42.667px;
      font-style: normal;
      font-weight: 600;
      line-height: 53.333px; /* 125% */
      letter-spacing: -0.853px;
    }

    .pages h2 {
      color: var(--colors-text-text-primary-900, #101828);
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px; /* 126.667% */
    }

    .pages h3 {
      color: var(--colors-text-text-primary-900, #101828);
      font-family: "DM Sans";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 150% */
    }

    .pages h4 {
      color: var(--colors-text-text-primary-900, #101828);
      font-size: 18.077px;
      font-style: normal;
      font-weight: 600;
      line-height: 27.116px; /* 150% */
    }

    .pages h5 {
      color: var(--Color-Brand-blue, #2240D9);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
    }

    .pages#Solucion ul,
    .pages#Solucion ol,
    .pages#Roadmap ul,
    .pages#Roadmap ol,
    .pages#Roadmap ul,
    .pages#Roadmap ol
     {
      list-style-type: disc;
      margin-left: 20px;
      color: var(--colors-text-text-primary-900, #101828);

      & li {
        & strong {

        }
        & p {
          padding: 10px 0px 0px 32px;
          color: var(--colors-text-text-tertiary-600, #475467);
          font-family: "DM Sans", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
        }
      }
    }

    .pages#Roadmap ul,
    .pages#Roadmap ol {
      list-style-type: none;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 32px;

      & li {
        padding: 32px;
        align-self: stretch;

        background-color: #F9FAFB;
        border: 1px solid #eaecf0;
        border-radius: 16px;
      }

      & h2, h3 {
        color: var(--colors-text-text-primary-900, #101828);

        font-family: Inter, sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px; /* 126.667% */
      }

      & ul, ol {
        list-style-type: disc;

        & li {
          padding: 10px 0px 0px 32px;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--spacing-md, 8px);
          align-self: stretch;

          color: var(--colors-text-text-tertiary-600, #475467);
          font-family: "DM Sans", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */

          border:none;

          & strong {
            display: block;
            color: var(--colors-text-text-primary-900, #101828);
            font-family: "DM Sans", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px; /* 150% */
          }
        }

      }
    }

    .pages#Requerimientos ul {
      ${'' /* list-style-type: none; */}

      display: grid;
      gap: 30px;
      grid-template-columns: 1fr 1fr;

      li {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        width: 343.468px;
        min-width: 253.082px;
        padding: var(--spacing-3xl, 21.693px);
        flex-shrink: 0;

        background: var(--Colors-Background-bg-secondary, #F9FAFB);
        border-radius: 12px;

        & strong {
          color: var(--colors-text-text-primary-900, #101828);
          font-family: Inter, sans-serif;
          font-size: 18.077px;
          font-style: normal;
          font-weight: 600;
          line-height: 27.116px; /* 150% */
        }

        & span {
          display: flex;
          width: 43.385px;
          height: 43.385px;
          padding: 10.846px;
          justify-content: center;
          align-items: center;

          border-radius: 10px;
          background: #0076CE;
        }
      }
    }

    .pages#Tecnologias-a-Utilizar ol,
    .pages#Tecnologias-a-Utilizar ul
    {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 32px;

      & li {
        display: flex;
        min-width: 560px;
        padding: 24px 24px 28px 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;

        border-radius: 16px;
        border: 1px solid #EAECF0;
        background:#FFFFFF;

        & h2 {
          color:  #2240D9;
          /* Text sm/Semibold */
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 142.857% */
        }

        & h3 {
          color: var(--colors-text-text-primary-900, #101828);
          /* Text lg/Semibold */
          font-family: Inter, sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px; /* 155.556% */
        }

        & p{
          color: var(--colors-text-text-tertiary-600, #475467);
          /* Text md/Regular */
          font-family: Inter, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */

          padding: 8px 0px 8px 0px;
        }
      }
    }

    .pages#Equipo-Requerido ul,
    .pages#Equipo-Requerido ol {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      gap: 32px var(--spacing-4xl, 32px);
      align-self: stretch;
      flex-wrap: wrap;

      & li {
        display: flex;
        min-width: 240px;
        padding: var(--spacing-3xl, 24px);
        flex-direction: column;
        align-items: center;

        background: var(--Colors-Background-bg-secondary, #F9FAFB);
        border-radius: 16px;
        border: 1px solid #EAECF0;

        & p:first-child {
          color: var(--colors-text-text-primary-900, #101828);
          text-align: center;

          /* Text lg/Semibold */
          font-family: Inter, sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px; /* 155.556% */
        }

        & ul {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          & li {
            padding: 0;
            border: none;
          }
        }
      }
    }

    .pages p,
    .pages li {
      color: var(--colors-text-text-tertiary-600, #475467);

      /* Text lg/Regular */
      font-family: "DM Sans", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 155.556% */
    }

    .top-img {
      width: 80px;
      height: 75.27px;
    }

    @page {
      size: A4;
      margin: 1cm;
    }
`;

const ProposalSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px 0;
  width: 100%;
  height: 100vh;
`;

const ProposalRowContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 0px 0px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  color: #003047;
`;

const ProposalContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0px 0px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 85px 50px 50px 50px;
`;

const ProposalWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px 0px;
  width: 100%;
  max-width: 900px;
  box-sizing: border-box;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  color: #0e1d39;
  background-color: #f5f7fa;
`;

const NameAndDateHeading = styled.h4`
  color: var(--Color-Brand-blue, #2240d9);
  font-family: Inter;
  font-size: 14.222px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.333px; /* 150% */
  letter-spacing: -0.285px;
  text-align: left;
`;

const Portada = styled.div`
  position: relative;
  margin: 0 auto;
  height: 100vh;
  min-height: 842px;
  width: 595px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
  font-size: 34.3px;
  color: var(--primary-navy);
  font-family: var(--font-dm-sans);
  box-sizing: border-box;

  p {
    color: var(--PRIMARY-NAVY, #0e1d39);
    font-family: 'DM Sans';
  }

  &.portada-img {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
  }
`;
const Content = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  min-height: 125px;
`;
const PresentationAnchor = styled.a`
  color: #fff;

  text-decoration: none;
  font-size: 18px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  outline: none;
  border: none;

  cursor: pointer;
  transition: all 0.2s ease-in-out;

  padding: var(--spacing-xl, 15.561px) 21.396px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 9.725px;

  border-radius: 7.882px;
  border: 1px solid #00aa58;
  background: #00aa58;
  box-shadow: 0px 1px 1.945px 0px rgba(16, 24, 40, 0.05);

  &:hover {
    background-color: #05914c;
  }
`;
const DownloadButton = styled.button`
  color: #fff;

  text-decoration: none;
  font-size: 18px;
  font-family: Inter, sans-serif;
  outline: none;
  border: none;

  cursor: pointer;
  transition: all 0.2s ease-in-out;

  padding: var(--spacing-xl, 15.561px) 21.396px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 9.725px;

  border-radius: 7.882px;
  border: 1px solid #2240d9;
  background: #2240d9;
  box-shadow: 0px 1px 1.945px 0px rgba(16, 24, 40, 0.05);

  &:hover {
    background-color: #1d2f7b;
  }
`;
const BackButton = styled.button`
  color: #2240d9;

  text-decoration: none;
  font-size: 18px;
  font-family: Inter, sans-serif;
  outline: none;
  border: none;

  cursor: pointer;
  transition: all 0.2s ease-in-out;

  padding: var(--spacing-xl, 15.561px) 21.396px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 9.725px;

  border-radius: 7.882px;
  border: 1px solid #2240d9;
  background: #fff;
  box-shadow: 0px 1px 1.945px 0px rgba(16, 24, 40, 0.05);
`;

const ProposalFixedContainer = styled.nav`
  position: fixed;
  z-index: 1000;
  right: 50px;
  top: 40px;

  display: flex;
  flex-direction: column;
  gap: 12px;

  width: fit-content;
  height: fit-content;
`;

const InputChangeName = styled.input`
  display: none;
  width: fit-content;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background-color: transparent;
  color: #101828;
  font-size: 16px;
  line-height: 28.49px;
  font-weight: 600;
  cursor: pointer;
  z-index: 1;

  color: var(--colors-text-text-primary-900, #101828);
  font-family: Inter, sans-serif;
  font-size: 42.667px;
  font-style: normal;
  font-weight: 600;
  line-height: 53.333px; /* 125% */
  letter-spacing: -0.853px;
  text-align: left;
`;

const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const PieChartImg = styled.div`
  width: 23.341px;
  height: 23.341px;
`;

const ProposalTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 100%;

  color: var(--colors-text-text-primary-900, #101828);
  font-family: Inter, sans-serif;
  font-size: 42.667px;
  font-style: normal;
  font-weight: 600;
  line-height: 53.333px; /* 125% */
  letter-spacing: -0.853px;
  text-align: left;
`;

const ProposalTittle = styled.h1`
  color: var(--colors-text-text-primary-900, #101828);
  font-family: Inter, sans-serif;
  font-size: 42.667px;
  font-style: normal;
  font-weight: 600;
  line-height: 53.333px; /* 125% */
  letter-spacing: -0.853px;
  text-align: left;
`;

const Spiner = styled.span`
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border-bottom: 2px solid #ffffff;

  animation: ${spin} 1s infinite;
`;

const ProposalPage = () => {
  const history = useHistory();
  const { threadId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [proposalData, setProposalData] = useState({});
  const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);
  const [isDownloadingPPTX, setIsDownloadingPPTX] = useState(false);
  const [presentationURL, setPresentationURL] = useState(null);
  const [selectedText, setSelectedText] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    fetchHistoryInOrderToRender({ threadId }).then((data) => {
      Array.isArray(data.messages) &&
        data.messages.length > 0 &&
        setMessages(data.messages);
      setProposalData((prev) => ({
        ...prev,
        name: data.conversation_name,
      }));
    });
    setIsLoading(false);
  }, [threadId]);

  const renameChat = async ({ threadId, name }) => {
    try {
      if (name) {
        await renameHistoryChat({ threadId, name });
        setProposalData((prev) => ({ ...prev, name }));
      }

      return;
    } catch (error) {
      console.error('Error al renombrar el chat', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditName = ({ threadId }) => {
    const div = document.getElementById(`div-${threadId}`);
    const input = document.getElementById(`input-${threadId}`);
    try {
      div.style.display = 'none';
      input.style.display = 'block';
      input.focus();

      input.addEventListener('blur', () => {
        renameChat({ threadId, name: input.value });
        div.style.display = 'flex';
        input.style.display = 'none';
      });

      input.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          renameChat({ threadId, name: input.value });
          div.style.display = 'flex';
          input.style.display = 'none';
        }
      });
    } catch (error) {
      console.error('Error al renombrar el chat', error);
    }

    return () => {
      div.style.display = 'flex';
      input.style.display = 'none';
    };
  };

  const handleMouseUp = () => {
    const selection = window.getSelection();
    if (selection.type === 'Caret') {
      setSelectedText(null);
      return;
    }
    if (selection.type === 'Range') {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();

      setSelectedText({
        range,
        position: {
          top: rect.top + rect.height / 2 + window.scrollY,
          left: rect.width + rect.left,
        },
      });
    }
  };

  const handleDownloadPDF = async ({ threadId }) => {
    if (isDownloadingPDF) return;
    try {
      setIsDownloadingPDF(true);
      await downloadInPDF({ threadId });
    } catch (error) {
      console.error('Error al descargar el PDF', error);
    } finally {
      setIsDownloadingPDF(false);
    }
  };

  const handleDownloadPPTX = async ({ threadId }) => {
    if (isDownloadingPPTX) return;
    try {
      setIsDownloadingPPTX(true);
      const { url, error } = await downloadPPTX({ threadId });

      if (error) {
        console.error('Error al descargar el PPTX', error);
        return;
      }

      if (!url) {
        console.error('Error al descargar el PPTX', error);
        return;
      }

      setPresentationURL(url);
      return;
    } catch (error) {
      console.error('Error al descargar el PPTX', error);
    } finally {
      setIsDownloadingPPTX(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <>
          <ProposalSkeleton>
            <Loader />
          </ProposalSkeleton>
        </>
      ) : (
        <>
          <ProposalStyles />
          <ProposalRowContainer>
            <Navbar />

            <ProposalContent>
              <ProposalWrapper>
                <ProposalTitleWrapper
                  onClick={() => handleEditName({ threadId })}
                >
                  {isLoading ? (
                    <></>
                  ) : (
                    <>
                      <ProposalTittle
                        name={proposalData.name}
                        id={`div-${threadId}`}
                        value={proposalData.name}
                      >
                        {proposalData.name}
                      </ProposalTittle>
                      <InputChangeName
                        type="text"
                        name="chat_name"
                        id={`input-${threadId}`}
                        defaultValue={proposalData.name}
                      />

                      <ImgWrapper id="edit-pencil">
                        <SVGComponent
                          src={'/v1/swarm/pencil-01.svg'}
                          strokeColor={'#2240d9'}
                        />
                      </ImgWrapper>
                    </>
                  )}
                </ProposalTitleWrapper>

                {isLoading ? (
                  <ProposalSkeleton />
                ) : (
                  messages.length > 0 &&
                  messages.map((item, index) => (
                    <div
                      className="pages relative"
                      id={item.message_name.replace(/ /g, '-')}
                      onMouseUp={handleMouseUp}
                      // dangerouslySetInnerHTML={{ __html: item.response }}
                    >
                      <ReactMarkdown>{item.response}</ReactMarkdown>
                    </div>
                  ))
                )}
                {selectedText && (
                  <AskAITooltip
                    selectedText={selectedText}
                    style={{
                      position: 'absolute',
                      top: `${selectedText.position.top}px`,
                      left: `${selectedText.position.left / 2}px`,
                    }}
                  />
                )}
              </ProposalWrapper>

              <ProposalFixedContainer id="proposal-navigator">
                {/* TODO: Cambiar esta lógica por roles de usuario con validacion desde el backend */}
                {!localStorage.getItem('token') ? (
                  <></>
                ) : (
                  <>
                    {/* <BackButton
                      type="button"
                      onClick={() => history.push('/v1/swarm/chat')}
                    >
                      Ver análisis completo
                      <PieChartImg>
                        <SVGComponent
                          src={'/v1/swarm/pie-chart.svg'}
                          strokeColor={'#2240d9'}
                        />
                      </PieChartImg>
                    </BackButton>

                    <DownloadButton
                      type="button"
                      onClick={() => handleDownloadPDF({ threadId })}
                    >
                      Descargar PDF
                      <PieChartImg>
                        {isDownloadingPDF ? (
                          <Spiner />
                        ) : (
                          <SVGComponent src={'/v1/swarm/download.svg'} />
                        )}
                      </PieChartImg>
                    </DownloadButton>

                    {presentationURL ? (
                      <PresentationAnchor
                        target="_blank"
                        rel="noreferrer"
                        href={presentationURL}
                        onClick={() => setPresentationURL(null)}
                      >
                        Ir a la Presentación
                      </PresentationAnchor>
                    ) : (
                      <DownloadButton
                        type="button"
                        onClick={() => handleDownloadPPTX({ threadId })}
                      >
                        Descargar PPTX
                        <PieChartImg>
                          {isDownloadingPPTX ? (
                            <Spiner />
                          ) : (
                            <SVGComponent src={'/v1/swarm/download.svg'} />
                          )}
                        </PieChartImg>
                      </DownloadButton>
                    )} */}
                  </>
                )}

                <ProposalNavbar
                  navElements={messages.map((item) =>
                    item.message_name.replace(/ /g, '-')
                  )}
                />
              </ProposalFixedContainer>
            </ProposalContent>
          </ProposalRowContainer>
        </>
      )}
    </>
  );
};

export default ProposalPage;
