import { GetAgentsRepository } from './repositories/getAgents.repository';
import { GetAgentWithFlowsRepository } from './repositories/getAgentWithFlows.repository';
import { ExecuteGraphRepository } from './repositories/executeGraph.repository';
import { AgentsPorts } from '../domain/ports/agents.ports';
import { IFailureHandler } from '../../../core/interface/IFailureHandler';
import { GetUserClientsRepository } from './repositories/getClients.repository';

/**
 * @typedef {import('../domain/entities/graphExecution.entity').ExecuteGraphDTO} ExecuteGraphDTO
 * @typedef {import('../domain/entities/graphExecution.entity').ExecuteGraphResponseDTO} ExecuteGraphResponseDTO
 */

/**
 * @implements {AgentsPorts}
 */
export class AgentsRepository extends AgentsPorts {
  constructor() {
    super();
    this.failureHandler = new IFailureHandler();
    this.getAgentsRepository = new GetAgentsRepository(this.failureHandler);
    this.getAgentWithFlowsRepository = new GetAgentWithFlowsRepository(
      this.failureHandler
    );
    this.executeGraphRepository = new ExecuteGraphRepository(
      this.failureHandler
    );
    this.getUserclientRepository = new GetUserClientsRepository(
      this.failureHandler
    );
  }

  /**
   * @inheritdoc
   */
  async getAgents(dataGetAgents) {
    return await this.getAgentsRepository.getAgents(dataGetAgents);
  }

  /**
   * @inheritdoc
   */
  async getAgentWithFlows(id) {
    return await this.getAgentWithFlowsRepository.getAgentWithFlows(id);
  }

  /**
   * @inheritdoc
   */
  async getClientsByName(data) {
    return await this.getUserclientRepository.getClientsByName(data);
  }

  /**
   * @inheritdoc
   * @param {ExecuteGraphDTO} executeDto - DTO for graph execution
   * @returns {Promise<ExecuteGraphResponseDTO>} - Response from the graph execution
   * @throws {Error} - If the execution fails
   */
  async executeGraph(executeDto) {
    return await this.executeGraphRepository.executeGraph(executeDto);
  }
}
