import log from "loglevel";

const originalFactory = log.methodFactory;

log.methodFactory = (methodName, logLevel, loggerName) => {
  const rawMethod = originalFactory(methodName, logLevel, loggerName);
  return (...args) => {
    rawMethod(args.join(" "));
  };
};

log.setLevel(log.getLevel()); // Apply the new method factory

export default log;
