import styled, { keyframes } from 'styled-components';
import { useEffect, useState } from 'react';
import SVGComponent from '../SVGComponent';

const grow = keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  min-height: 100vh;
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #f9f9f9;
  color: #003047;
`;

const LoaderStep = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  width: 240px;
  height: auto;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xl, 16px);
`;

const ImgWrapper = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  width: 56px;
  height: 56px;
  padding: var(--spacing-none, 14px);
  justify-content: center;
  align-items: center;

  color: var(--colors-text-text-secondary-700, #344054);
  border-radius: var(--radius-xl, 12px);
  background: ${(props) => props.bgColor || '#2240d9'};
  box-shadow: 0px 0px 0px 3px #d2ecff;
`;

const StepTittle = styled.h4`
  color: var(--colors-text-text-secondary-700, #344054);
  text-align: center;

  /* Text md/Semibold */
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
`;

const StepDescription = styled.p`
  color: var(--colors-text-text-tertiary-600, #475467);
  text-align: center;

  /* Text md/Regular */
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

const StepContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px 0;
`;

const Spin = styled.span`
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border-bottom: 2px solid #2240d9;

  animation: ${spin} 1s infinite;
`;

const ProgressLine = styled.div`
  position: absolute;
  top: 30px;
  left: 50%;
  z-index: 5;
  width: ${(props) => props.width}%;
  height: 2px;
  background-color: #2240d9;
  border-radius: 2px;
`;

const StepLoader = () => {
  const [width, setWidth] = useState(30);
  const [step, setStep] = useState(1);
  const increment = 300 / (20 * 60);

  useEffect(() => {
    const interval = setInterval(() => {
      setWidth((prev) => {
        const next = prev + increment;
        if (next > (330 / 4) * 1) {
          setStep(2);
        }
        if (next > (330 / 4) * 2) {
          setStep(3);
        }
        if (next > (330 / 4) * 3) {
          setStep(4);
        }
        return next > 300 ? 300 : next;
      });
    }, 300);

    return () => clearInterval(interval);
  }, []);

  return (
    <LoaderContainer>
      <LoaderWrapper>
        <LoaderStep>
          <ProgressLine width={width} />
          <ImgWrapper bgColor={step < 2 ? '#FFFFFF' : '#2240d9'}>
            {step === 1 ? (
              <Spin />
            ) : (
              <SVGComponent
                src={'/v1/swarm/puzzle-piece.svg'}
                strokeColor={step >= 1 ? '#ffffff' : '#344054'}
              />
            )}
          </ImgWrapper>
          <StepContentWrapper>
            <StepTittle>Analizing</StepTittle>
            <StepDescription>project requirements</StepDescription>
          </StepContentWrapper>
        </LoaderStep>
        <LoaderStep>
          <ImgWrapper bgColor={step < 3 ? '#FFFFFF' : '#2240d9'}>
            {step === 2 ? (
              <Spin />
            ) : (
              <SVGComponent
                src={'/v1/swarm/container.svg'}
                strokeColor={step >= 2 ? '#ffffff' : '#344054'}
              />
            )}
          </ImgWrapper>
          <StepContentWrapper>
            <StepTittle>Dimensioning and planning</StepTittle>
            <StepDescription>the project scope and resources</StepDescription>
          </StepContentWrapper>
        </LoaderStep>
        <LoaderStep>
          <ImgWrapper bgColor={step < 4 ? '#FFFFFF' : '#2240d9'}>
            {step === 3 ? (
              <Spin />
            ) : (
              <SVGComponent
                src={'/v1/swarm/data.svg'}
                strokeColor={step >= 3 ? '#ffffff' : '#344054'}
              />
            )}
          </ImgWrapper>
          <StepContentWrapper>
            <StepTittle>Building</StepTittle>
            <StepDescription>personalized solutions</StepDescription>
          </StepContentWrapper>
        </LoaderStep>
        <LoaderStep>
          <ImgWrapper bgColor={step < 4 ? '#FFFFFF' : '#2240d9'}>
            {step === 4 ? (
              <Spin />
            ) : (
              <SVGComponent
                src={'/v1/swarm/stars.svg'}
                strokeColor={step >= 4 ? '#ffffff' : '#344054'}
              />
            )}
          </ImgWrapper>
          <StepContentWrapper>
            <StepTittle>Polishing</StepTittle>
            <StepDescription>executive summary</StepDescription>
          </StepContentWrapper>
        </LoaderStep>
      </LoaderWrapper>
    </LoaderContainer>
  );
};

export default StepLoader;
