import { IFailureHandler } from '../../../../core/interface/IFailureHandler';
import { AgentsPorts } from '../ports/agents.ports';
import { IUseCase } from '../../../../core/interface/IUseCase';

export class GetClientsByNameUseCase extends IUseCase {
  agentsRepository;
  failureHandler;

  /**
   * Constructor for the GetAgentUseCase class
   * @param {AgentsPorts} agentsRepository - The repository to get agents
   * @param {IFailureHandler} failureHandler - The failure handler to handle errors
   */
  constructor(agentsRepository, failureHandler) {
    super();
    this.agentsRepository = agentsRepository;
    this.failureHandler = failureHandler;
  }
  /**
   * @inheritdoc
   * @param {string} name - The name of the client to search for
   */
  async execute(name) {
    try {
      const clients = await this.agentsRepository.getClientsByName({ name });
      return clients;
    } catch (error) {
      this.failureHandler.logAndMapExceptionToFailure(error);
      throw error;
    }
  }
}
