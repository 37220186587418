import { useExecuteAgentContext } from '../../contexts/ExecuteAgents.context';
import DebounceSelector from '../DebounceSelector/DebounceSelector';
import useGetUserClients from '../../presentation/hooks/use-get-user-clients.hook';
import { ClientSelectorWrapper } from './ClientSelector.styles';

const ClientSelector = () => {
  const { updateClient, client } = useExecuteAgentContext();
  const { executeCallback: getUserClients } = useGetUserClients();

  const handleSearchClients = async (search = '') => {
    try {
      const results = await getUserClients(search);
      return results.map((client) => ({
        id: client.id,
        name: client.name,
      }));
    } catch (error) {
      console.error('Error fetching clients:', error);
      return [];
    }
  };

  const handleClientSelect = (value, selectedClient) => {
    updateClient(selectedClient);
  };

  return (
    <ClientSelectorWrapper>
      <span>Client:</span>
      <DebounceSelector
        initialValue={client?.name}
        name="client_id"
        placeholder="Search Client..."
        onSearch={handleSearchClients}
        onSelect={handleClientSelect}
      />
    </ClientSelectorWrapper>
  );
};

export default ClientSelector;
